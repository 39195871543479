import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { connect } from 'react-redux';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import { LeftSidebar, MinimalLayout } from './layout-blueprints';
import { CUSTOM_REPORTS, ROUTING_CONSTANTS } from 'constants/constants';

// Pages
const Dashboard = lazy(() => import('./pages/Dashboard'));

const Customer = lazy(() => import('./pages/Customer'));
const CreateCustomer = lazy(() => import('./pages/CreateCustomer'));

const Designation = lazy(() => import('./pages/Designation'));
const CreateDesignation = lazy(() => import('./pages/CreateDesignation'));

const CreateUserId = lazy(() => import('./pages/UserIdSetup'));

const Login = lazy(() => import('./pages/Login'));

const Company = lazy(() => import('./pages/Company'));
const CreateCompany = lazy(() => import('./pages/CreateCompany'));

const Department = lazy(() => import('./pages/Department'));
const CreateDepartment = lazy(() => import('./pages/CreateDepartment'));

const Location = lazy(() => import('./pages/Location'));
const CreateLocation = lazy(() => import('./pages/CreateLocation'));
const Actions = lazy(() => import('./pages/Actions'));
const ActionsAndReasons = lazy(() => import('./pages/ActionsAndReasons'));
const CreateAction = lazy(() => import('./pages/CreateAction'));
const EarningDeductionCode = lazy(() => import('./pages/EarningDeductionCode'));
const CreateEarningDeductionCodes = lazy(() =>
  import('./pages/CreateEarningDeductionCodes')
);
const PayCalendar = lazy(() => import('./pages/PayCalendar'));
const CreatePayCalendar = lazy(() => import('./pages/CreatePayCalendar'));
const EmployeePaymentOptions = lazy(() =>
  import('./pages/EmployeePaymentOptions')
);
const CreateActionReason = lazy(() => import('./pages/CreateActionReason'));
const AddCertificateLicense = lazy(() =>
  import('./pages/AddCertificateLicense')
);

const AutoNumberingSetup = lazy(() => import('./pages/AutoNumberingSetup'));
const Hire = lazy(() => import('./pages/HireProcess'));
const HireProcessView = lazy(() => import('./pages/HireProcessView'));
const CreateRoles = lazy(() => import('./pages/CreateRoles'));
const RolesAndPermissions = lazy(() => import('./pages/RolesAndPermissions'));
const NationalID = lazy(() => import('./pages/NationalID'));
const CreateNationalID = lazy(() => import('./pages/CreateNationalID'));
const AddWorkExperience = lazy(() => import('./pages/AddWorkExperience'));

const AddEmergencyContact = lazy(() => import('./pages/AddEmergencyContact'));
const ModuleAccess = lazy(() => import('./pages/ModuleAccess'));

const AddPhoneAndEmailAddress = lazy(() =>
  import('./pages/AddPhoneAndEmailAddress')
);
const AddAddress = lazy(() => import('./pages/AddAddress'));

const createItemcatalogue = lazy(() => import('./pages/CreateItemCatalogue'));

const CreateDependants = lazy(() => import('./pages/CreateDependants'));

const AddEducation = lazy(() => import('./pages/AddEducation'));
const UpdateEmployeeId = lazy(() => import('./pages/DeleteUpdateID'));
const itemCatalogue = lazy(() => import('./pages/DashboardItemCatalogue'));
const Permissions = lazy(() => import('./pages/PermissionsAssignment'));
const uploadResults = lazy(() => import('./pages/UploadResults'));
const downloadResults = lazy(() => import('./pages/DownloadResults'));
const CreateEmployeeUserId = lazy(() => import('./pages/CreateEmployeeUserId'));
const Employees = lazy(() => import('./pages/Employees'));
const ActiveInActive = lazy(() => import('./pages/ActiveInActive'));
const ResetPasswordByAdmin = lazy(() => import('./pages/ResetPasswordByAdmin'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const CreateUserCredentials = lazy(() =>
  import('./pages/CreateUserCredentials')
);
const PasswordRules = lazy(() => import('./pages/PasswordRules'));
const EmployeeDetails = lazy(() => import('./pages/ViewEmployeeDetails'));
const OrgStructure = lazy(() => import('./pages/OrgStructure'));
const CreateBank = lazy(() => import('./pages/CreateBank'));
const Bank = lazy(() => import('./pages/Bank'));
const CreateSourceBank = lazy(() => import('./pages/CreateSourceBank'));
const DashboardSourceBank = lazy(() => import('./pages/DashboardSourceBank'));
const OrgChartSetup = lazy(() => import('./pages/OrgChartSetup'));
const CreateBankBranches = lazy(() => import('./pages/CreateBankBranches'));
const BankBranches = lazy(() => import('./pages/BankBranches'));
const UploadBulkEmployees = lazy(() => import('./pages/BulkUploadEmployees'));

const UploadLocation = lazy(() => import('./pages/UploadLocation'));
const UploadDepartment = lazy(() => import('./pages/UploadDepartment'));
const UploadDesignation = lazy(() => import('./pages/UploadDesignation'));
const UploadEmployeeNationalID = lazy(() =>
  import('./pages/UploadEmployeeNationalID')
);
const UploadEmployeeAddress = lazy(() =>
  import('./pages/UploadEmployeeAddress')
);
const UploadPhone = lazy(() => import('./pages/UploadPhone'));
const UploadEmailID = lazy(() => import('./pages/UploadEmailID'));
const UploadEmergencyContact = lazy(() =>
  import('./pages/UploadEmergencyContact')
);
const UploadDependants = lazy(() => import('./pages/UploadDependants'));
const UploadBeneficiary = lazy(() => import('./pages/UploadBeneficiary'));
const UploadWorkExperience = lazy(() => import('./pages/UploadWorkExperience'));
const UploadDocument = lazy(() => import('./pages/UploadDocument'));
const UploadCertificate = lazy(() => import('./pages/UploadCertificate'));
const UploadLicense = lazy(() => import('./pages/UploadLicense'));

const CreateJobBand = lazy(() => import('./pages/CreateJobBand'));
const JobBand = lazy(() => import('./pages/JobBand'));
const UploadJobGrade = lazy(() => import('./pages/UploadJobGrade'));
const UploadJobBands = lazy(() => import('./pages/UploadJobBands'));
const UploadCompanyPolicy = lazy(() => import('./pages/UploadCompanyPolicy'));
const CreateJobGrade = lazy(() => import('./pages/CreateJobGrade'));
const JobGrade = lazy(() => import('./pages/JobGrade'));
const EmployeeInfoHistory = lazy(() => import('./pages/EmployeeInfoHistory'));
const EmployeeDependantOrBeneficiaryDetails = lazy(() =>
  import('./pages/EmployeeDependantOrBeneficiaryDetails')
);

const NationalIDReport = lazy(() => import('./pages/NationalIDReport'));
const EmployeeDetailReports = lazy(() =>
  import('./pages/EmployeeDetailReports')
);
const EditBiographicalDetails = lazy(() =>
  import('./pages/EditBiographicalDetails')
);
const EmergencyContactReport = lazy(() =>
  import('./pages/EmergencyContactReport')
);
const DepartmentReport = lazy(() => import('./pages/DepartmentReport'));
const ProbationConfirmation = lazy(() =>
  import('./pages/ProbationConfirmation')
);
const MyProfile = lazy(() => import('./pages/MyProfile'));
const ResignationDetails = lazy(() => import('./pages/ResignationDetails'));
const MyPeers = lazy(() => import('./pages/MyPeers'));
const PublishAnnouncement = lazy(() => import('./pages/PublishAnnouncement'));
const EmployeeCertificateOrLicenseReports = lazy(() =>
  import('./pages/EmployeeCertificateOrLicenseReports')
);
const EmployeeRolesReport = lazy(() => import('./pages/EmployeeRolesReport'));
const EmployeeTimeline = lazy(() => import('./pages/EmployeeTimeline'));
const MyTeam = lazy(() => import('./pages/MyTeam'));
const InitiateActions = lazy(() => import('./pages/InitiateActions'));
const CreateLetters = lazy(() => import('./pages/CreateLetters'));
const Letters = lazy(() => import('./pages/LettersDashboard'));
const HolidayCalendarMaster = lazy(() =>
  import('./pages/HolidayCalendarMaster')
);
const GenetateLetter = lazy(() => import('./pages/GenerateLetter'));
const HolidayCalendar = lazy(() => import('./pages/HolidayCalendar'));
const HolidayCalendarConfiguration = lazy(() =>
  import('./pages/HolidayCalendarConfiguration')
);
const TransactionSummary = lazy(() => import('./pages/TransactionSummary'));
const ManagerTransactionSummary = lazy(() =>
  import('./pages/ManagerTransactionSummary')
);
const MyTransactionSummary = lazy(() => import('./pages/MyTransactionSummary'));
const CreateLeaveAccrualPolicy = lazy(() =>
  import('./pages/LeaveAccrualPolicy')
);
const AccrualPolicy = lazy(() => import('./pages/AccrualPolicy'));

const ManagerChangeUpload = lazy(() => import('./pages/ManagerChangeUpload'));
const EmployeeHireSeparation = lazy(() => import('./pages/HireSeparation'));
const EmployeeMissingInfoReport = lazy(() =>
  import('./pages/EmployeeMissingInfoReport')
);
const EmployeeBloodGroup = lazy(() => import('./pages/EmployeeBloodGroup'));
const ExitControl = lazy(() => import('./pages/ExitControls'));
const ProbationPeriodSetup = lazy(() => import('./pages/ProbationPeriodSetup'));
const PendingApprovals = lazy(() => import('./pages/PendingApprovals'));
const EmployeePriorWorkExperienceReport = lazy(() =>
  import('./pages/EmployeePriorWorkExperienceReport')
);
const LeaveBalanceReport = lazy(() => import('./pages/LeaveBalanceReport'));
const LeaveProgramDetailsReport = lazy(() =>
  import('./pages/LeaveProgramDetailsReport')
);
const NotificationTemplate = lazy(() => import('./pages/NotificationTemplate'));
const CreateNotificationTemplate = lazy(() =>
  import('./pages/CreateNotificationTemplate')
);
const UserMapping = lazy(() => import('./pages/UserMapping'));
const ProbationControls = lazy(() => import('./pages/ProbationControls'));

const LeaveTypes = lazy(() => import('./pages/LeaveTypes'));
const CreateLeaveTypes = lazy(() => import('./pages/CreateLeaveTypes'));
const CreateLeaveAccumulation = lazy(() =>
  import('./pages/CreateLeaveAccumulation')
);
const LeaveAccumulation = lazy(() => import('./pages/LeaveAccumulation'));
const CreateLeaveEncashment = lazy(() =>
  import('./pages/CreateLeaveEncashment')
);
const LeaveEncashment = lazy(() => import('./pages/LeaveEncashment'));
const LeaveControl = lazy(() => import('./pages/LeaveControl'));
const LeaveProgram = lazy(() => import('./pages/LeaveProgram'));
const LeavePolicyDefinition = lazy(() =>
  import('./pages/LeavePolicyDefinition')
);
const CreateLeavePolicyDefinition = lazy(() =>
  import('./pages/CreateLeavePolicyDefinition')
);
const LeaveCompOff = lazy(() => import('./pages/LeaveCompOff'));
const MyLeaves = lazy(() => import('./pages/MyLeaves'));
const MyCompensation = lazy(() => import('./pages/EssApplyLoan'));
const UploadLeaveBalances = lazy(() => import('./pages/UploadLeaveBalances'));

const CreateMaternityLeavePolicy = lazy(() =>
  import('./pages/MaternityLeavePolicy')
);
const CreateCompOff = lazy(() => import('./pages/CompOffRules'));
const CreateLeaveProgram = lazy(() => import('./pages/CreateLeaveProgram'));
const ApplyLeave = lazy(() => import('./pages/ApplyLeave'));
const AssignLeaveProgram = lazy(() => import('./pages/AssignLeaveProgram'));
const LeaveHistory = lazy(() => import('./pages/LeaveHistory'));
const TeamLeaveCalendar = lazy(() => import('./pages/TeamLeaveCalendar'));
const CancelOrAdjustLeave = lazy(() => import('./pages/CancelOrAdjustLeave'));
const LeaveBalance = lazy(() => import('./pages/LeaveBalance'));
const PendingLeaveApprovals = lazy(() =>
  import('./pages/PendingLeaveApprovals')
);
const CompOffEligibility = lazy(() => import('./pages/CompOffEligibility'));
const ManagerLeaveHistory = lazy(() => import('./pages/MangerLeaveHistory'));
const TeamLeaveBalanceDetails = lazy(() =>
  import('./pages/TeamLeaveBalanceDetails')
);
const TeamLeaveBalance = lazy(() => import('./pages/TeamLeaveBalance'));
const ApplyProxyLeaveByManager = lazy(() =>
  import('./pages/ApplyProxyLeaveByManager')
);
const ApproveOrRejectLeaveRequest = lazy(() =>
  import('./pages/ApproveOrRejectLeaveRequest')
);
const ApplyProxyLeaveByAdmin = lazy(() =>
  import('./pages/ApplyProxyLeaveByAdmin')
);
const ManagerTeamLeaveCalendar = lazy(() =>
  import('./pages/ManagerTeamLeaveCalendar')
);
const ViewAdminLeaveHistory = lazy(() =>
  import('./pages/ViewAdminLeaveHistory')
);
const LeaveAdjustmentRequests = lazy(() =>
  import('./pages/LeaveAdjustmentRequests')
);

const AdminLeaveBalanceView = lazy(() =>
  import('./pages/AdminLeaveBalanceView')
);
const DefineShifts = lazy(() => import('./pages/DefineShifts'));
const AdminCompOffSummaryView = lazy(() =>
  import('./pages/AdminCompOffSummaryView')
);
const AdminViewShifts = lazy(() => import('./pages/AdminViewShifts'));
const DefinePattern = lazy(() => import('./pages/DefinePattern'));
const SearchDefinePattern = lazy(() => import('./pages/SearchDefinePattern'));
const UploadPattern = lazy(() => import('./pages/UploadPattern'));
const UploadLOPs = lazy(() => import('./pages/UploadLOPs'));
const UploadPFPTLWFESI = lazy(() => import('./pages/UploadPFPTLWFESI'));
const UploadOneTimeEarningDeductions = lazy(() =>
  import('./pages/UploadOneTimeEarningDeductions')
);
const TeamCompOffSummary = lazy(() => import('./pages/TeamCompOffSummary'));
const ProcessResults = lazy(() => import('./pages/ProcessResults'));
const ProcessResultDetails = lazy(() => import('./pages/ProcessResultDetails'));
const LeaveAccrual = lazy(() => import('./pages/LeaveAccrual'));
const CompOffReport = lazy(() => import('./pages/CompOffReport'));
const UpdateEncashmentStatus = lazy(() =>
  import('./pages/UpdateEncashmentStatus')
);
const CustomReport = lazy(() => import('./pages/customReport'));
const PendingLeaveRequestsReport = lazy(() =>
  import('./pages/PendingLeaveRequestsReport')
);
const EmployeeEncashmentReport = lazy(() =>
  import('./pages/EmployeeEncashmentReport')
);
const EmployeeTaxData = lazy(() => import('./pages/EmployeeTaxData'));
const SearchEmployeeTaxData = lazy(() =>
  import('./pages/SearchEmployeeTaxData')
);
const CreateOneTimeEarningDeduction = lazy(() =>
  import('./pages/CreateOneTimeEarningDeduction')
);
const SearchGeneralDeductions = lazy(() =>
  import('./pages/SearchGeneralDeductions')
);
const CreateGeneralDeductions = lazy(() =>
  import('./pages/CreateGeneralDeductions')
);
const AssignPayCalendar = lazy(() => import('./pages/AssignPayCalendar'));
const CreatePTSlab = lazy(() => import('./pages/CreatePTSlab'));
const CreatePayGroup = lazy(() => import('./pages/CreatePayGroup'));
const PayGroup = lazy(() => import('./pages/PayGroup'));
const AssignPayGroup = lazy(() => import('./pages/AssignPayGroup'));
const DefineCompensationStructure = lazy(() =>
  import('./pages/DefineCompensationStructure')
);
const SearchCompensationStructure = lazy(() =>
  import('./pages/SearchCompensationStructure')
);
const CreateSubSection = lazy(() => import('./pages/CreateSubSection'));
const SubSection = lazy(() => import('./pages/SubSection'));
const CreateLWFSlab = lazy(() => import('./pages/CreateLWFSlab'));
const AddEmployeeCompensation = lazy(() => import('./pages/AddEmpComp'));
const RunProcess = lazy(() => import('./pages/RunProcess'));
const LoanManagementSetup = lazy(() => import('./pages/LoanManagementSetup'));
const PayrollControls = lazy(() => import('./pages/PayrollControls'));
const PendingLoanApprovals = lazy(() => import('./pages/PendingLoanApprovals'));
const ApproveOrRejectLoanRequest = lazy(() =>
  import('./pages/ApproveOrRejectLoanRequest')
);
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TeamCompensation = lazy(() => import('./pages/ApplyProxyLoanByManager'));
const SearchITSection = lazy(() => import('./pages/SearchITSection'));
const CreateITSection = lazy(() => import('./pages/CreateITSection'));
const ApplyProxyLoanByAdmin = lazy(() =>
  import('./pages/ApplyProxyLoanByAdmin')
);
const InvestmentDeclaration = lazy(() =>
  import('./pages/InvestmentDeclaration')
);
const Section80G = lazy(() => import('./pages/Section80G'));
const POI = lazy(() => import('./pages/POI'));
const ViewCompensationHistory = lazy(() =>
  import('./pages/ViewCompensationHistory')
);
const CreatePaySlipTemplates = lazy(() =>
  import('./pages/CreatePaySlipTemplates')
);
const SalaryRevision = lazy(() => import('./pages/SalaryRevision'));
const MY_PAYSLIPS = lazy(() => import('./pages/MyPayslips'));
const MY_COMPENSATION_TEMPLATE = lazy(() =>
  import('./pages/MyCompensationTemplate')
);
const EmployeeCompensationReport = lazy(() =>
  import('./pages/EmployeeCompensationReport')
);
const UploadTDS = lazy(() => import('./pages/UploadTDS'));
const PayrollDashboard = lazy(() => import('./pages/PayrollDashboard'));
const PayrollProcessStage1SalarySheet = lazy(() =>
  import('./pages/PayrollProcessStage1SalarySheet')
);
const PayrollProcessStage3SalarySheet = lazy(() =>
  import('./pages/PayrollProcessStage3SalarySheet')
);

const Routes = (props) => {
  const { user, view, modules, customReports } = props;
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const PermissionChecker = ({
    Component,
    permId,
    isCustomReport = false,
    reportId
  }) => {
    console.log(permId);
    const permission = user?.permissions?.find(
      (permission) => permission.id === permId
    );
    const module = permission
      ? modules.find((module) => module.id === permission?.moduleId)
      : null;
    const hasModuleAccess = module != null && module.hasClientSubscribed;
    let customReport;
    if (isCustomReport)
      customReport = customReports.find((report) => report.rid === reportId);
    if (
      view?.toLowerCase() === 'admin' ||
      (permission && hasModuleAccess && (!isCustomReport || customReport))
    ) {
      return <Component />;
    } else {
      return <Redirect to={ROUTING_CONSTANTS.DASHBOARD.path} />;
    }
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to={ROUTING_CONSTANTS.LOGIN.path} />
            <Route
              path={[
                ROUTING_CONSTANTS.CREATE_COMPANY.path,
                ROUTING_CONSTANTS.DESIGNATION_DASHBOARD.path,
                ROUTING_CONSTANTS.ADD_DESIGNATION.path,
                ROUTING_CONSTANTS.ADD_DEPARTMENT.path,
                ROUTING_CONSTANTS.ADD_LOCATION.path,
                ROUTING_CONSTANTS.LOCATION_DASHBOARD.path,
                ROUTING_CONSTANTS.USERID_SETUP.path,
                ROUTING_CONSTANTS.DEPARTMENT_DASHBOARD.path,
                ROUTING_CONSTANTS.USERID_SETUP.path,
                ROUTING_CONSTANTS.AUTO_NUMBERING_SETUP.path,
                ROUTING_CONSTANTS.ADD_EMERGENCY_CONTACT.path,
                ROUTING_CONSTANTS.ADD_PHONE_AND_EMAIL.path,
                ROUTING_CONSTANTS.ADD_ADDRESS.path,
                ROUTING_CONSTANTS.VIEW_NATIONAL_ID.path,
                ROUTING_CONSTANTS.ADD_NATIONAL_ID.path,
                ROUTING_CONSTANTS.HIRE_PROCESS.path,
                ROUTING_CONSTANTS.HIRE_PROCESS_VIEW.path,
                ROUTING_CONSTANTS.ACTIONS.path,
                ROUTING_CONSTANTS.SEARCH_ACTION_REASON.path,
                ROUTING_CONSTANTS.CREATE_ACTION.path,
                ROUTING_CONSTANTS.CREATE_EARNING_DEDUCTION_CODES.path,
                ROUTING_CONSTANTS.EARNING_DEDUCTION_CODES.path,
                ROUTING_CONSTANTS.DEFINE_COMPENSATION_STRUCTURE.path,
                ROUTING_CONSTANTS.SEARCH_COMPENSATION_STRUCTURE.path,
                ROUTING_CONSTANTS.LOAN_MANAGEMENT_SETUP.path,
                ROUTING_CONSTANTS.CREATE_PAY_CALENDAR.path,
                ROUTING_CONSTANTS.PAY_CALENDAR.path,
                ROUTING_CONSTANTS.ASSIGN_PAY_CALENDAR.path,
                ROUTING_CONSTANTS.EMPLOYEE_PAYMENT_OPTIONS.path,
                ROUTING_CONSTANTS.CREATE_ACTION_REASON.path,
                ROUTING_CONSTANTS.ADD_WORK_EXPERIENCE.path,
                ROUTING_CONSTANTS.ADD_DEPENDENTS.path,
                ROUTING_CONSTANTS.ADD_EDUCATIONAL_DETAILS.path,
                ROUTING_CONSTANTS.ITEM_CATALOGUE_DASHBOARD.path,
                ROUTING_CONSTANTS.CREATE_ITEM_CATALOGUE.path,
                ROUTING_CONSTANTS.ADD_CERTIFICATE_OR_LICENSE.path,
                ROUTING_CONSTANTS.MODULE_ACCESS.path,
                ROUTING_CONSTANTS.CREATE_USER_ID.path,
                ROUTING_CONSTANTS.VIEW_EMPLOYEES.path,
                ROUTING_CONSTANTS.ACTIVE_INACTIVE.path,
                ROUTING_CONSTANTS.RESET_PASSWORD_BY_ADMIN.path,
                ROUTING_CONSTANTS.CREATE_USER_CREDENTIALS.path,
                ROUTING_CONSTANTS.PASSWORD_RULES.path,
                ROUTING_CONSTANTS.VIEW_EMPLOYEE_DETAILS.path,
                ROUTING_CONSTANTS.ORG_STRUCTURE.path,
                ROUTING_CONSTANTS.BANK.path,
                ROUTING_CONSTANTS.CREATE_BANK.path,
                ROUTING_CONSTANTS.CREATE_SOURCE_BANK.path,
                ROUTING_CONSTANTS.SOURCE_BANK.path,
                ROUTING_CONSTANTS.ORG_CHART_SETUP.path,
                ROUTING_CONSTANTS.BANK_BRANCHES.path,
                ROUTING_CONSTANTS.CREATE_BANK_BRANCHES.path,
                ROUTING_CONSTANTS.UPLOAD_RESULTS.path,
                ROUTING_CONSTANTS.UPLOAD_EMPLOYEES.path,
                ROUTING_CONSTANTS.UPLOAD_DEPARTMENT.path,
                ROUTING_CONSTANTS.UPLOAD_LOCATION.path,
                ROUTING_CONSTANTS.UPLOAD_DESIGNATION.path,
                ROUTING_CONSTANTS.UPLOAD_NATIONAL_ID.path,
                ROUTING_CONSTANTS.UPLOAD_ADDRESS.path,
                ROUTING_CONSTANTS.UPLOAD_PHONE.path,
                ROUTING_CONSTANTS.UPLOAD_EMAIL_ID.path,
                ROUTING_CONSTANTS.UPLOAD_EMERGENCY_CONTACT.path,
                ROUTING_CONSTANTS.UPLOAD_DEPENDANTS.path,
                ROUTING_CONSTANTS.UPLOAD_BENEFICIARY.path,
                ROUTING_CONSTANTS.UPLOAD_WORK_EXPERIENCE.path,
                ROUTING_CONSTANTS.UPLOAD_DOCUMENT.path,
                ROUTING_CONSTANTS.UPLOAD_CERTIFICATE.path,
                ROUTING_CONSTANTS.UPLOAD_LICENSE.path,
                ROUTING_CONSTANTS.UPLOAD_JOB_GRADE.path,
                ROUTING_CONSTANTS.UPLOAD_JOB_BAND.path,
                ROUTING_CONSTANTS.UPLOAD_COMPANY_POLICY.path,
                ROUTING_CONSTANTS.CREATE_JOB_BAND.path,
                ROUTING_CONSTANTS.JOB_BAND.path,
                ROUTING_CONSTANTS.CREATE_JOB_GRADE.path,
                ROUTING_CONSTANTS.JOB_GRADE.path,
                ROUTING_CONSTANTS.PUBLISH_ANNOUNCEMENT.path,
                ROUTING_CONSTANTS.EDIT_BIOGRAPHICAL_DETAILS.path,
                ROUTING_CONSTANTS.EMPLOYEE_INFO_HISTORY.path,
                ROUTING_CONSTANTS.DOWNLOAD_RESULTS.path,
                ROUTING_CONSTANTS.NATIONAL_ID_REPORT.path,
                ROUTING_CONSTANTS.EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS
                  .path,
                ROUTING_CONSTANTS.EMERGENCY_CONTACT_REPORT.path,
                ROUTING_CONSTANTS.EMPLOYEE_DETAILS_REPORT.path,
                ROUTING_CONSTANTS.DEPARTMENT_REPORT.path,
                ROUTING_CONSTANTS.EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path,
                ROUTING_CONSTANTS.EMPLOYEE_ROLES_REPORT.path,
                ROUTING_CONSTANTS.MANAGER_CHANGE_UPLOAD.path,
                ROUTING_CONSTANTS.EMPLOYEE_HIRE_SEPARATION.path,
                ROUTING_CONSTANTS.EMPLOYEE_MISSING_INFO_REPORT.path,
                ROUTING_CONSTANTS.EMPLOYEE_PRIOR_WORK_EXPERIENCE_REPORT.path,
                ROUTING_CONSTANTS.MY_PEERS.path,
                ROUTING_CONSTANTS.SUBMIT_RESIGNATION.path,
                ROUTING_CONSTANTS.EMPLOYEE_TIMELINE.path,
                ROUTING_CONSTANTS.LETTERS.path,
                ROUTING_CONSTANTS.CREATE_LETTERS.path,
                ROUTING_CONSTANTS.GENERATE_LETTERS.path,
                ROUTING_CONSTANTS.PROBATION_CONFIRMATION.path,
                ROUTING_CONSTANTS.EXIT_CONTROLS.path,
                ROUTING_CONSTANTS.EMPLOYEE_BLOOD_GROUP.path,
                ROUTING_CONSTANTS.HOLIDAY_CALENDER_MASTER.path,
                ROUTING_CONSTANTS.HOLIDAY_CALENDER.path,
                ROUTING_CONSTANTS.HOLIDAY_CALENDER_CONFIGURATION.path,
                ROUTING_CONSTANTS.CREATE_NOTIFICATION_TEMPLATE.path,
                ROUTING_CONSTANTS.PROBATION_PERIOD_SETUP.path,
                ROUTING_CONSTANTS.USER_MAPPING.path,
                ROUTING_CONSTANTS.PROBATION_CONTROL.path,
                ROUTING_CONSTANTS.CREATE_ROLES.path,
                ROUTING_CONSTANTS.TRANSACTION_SUMMARY.path,
                ROUTING_CONSTANTS.MY_TRANSACTION_SUMMARY.path,
                ROUTING_CONSTANTS.LEAVE_TYPES.path,
                ROUTING_CONSTANTS.CREATE_LEAVE_TYPES.path,
                ROUTING_CONSTANTS.LEAVE_ACCUMULATION.path,
                ROUTING_CONSTANTS.CREATE_LEAVE_ACCUMULATION.path,
                ROUTING_CONSTANTS.LEAVE_ENCASHMENT.path,
                ROUTING_CONSTANTS.CREATE_LEAVE_ENCASHMENT.path,
                ROUTING_CONSTANTS.LEAVE_POLICY_DEFINITION.path,
                ROUTING_CONSTANTS.CREATE_LEAVE_POLICY_DEFINITION.path,
                ROUTING_CONSTANTS.LEAVE_COMP_OFF.path,
                ROUTING_CONSTANTS.UPLOAD_LEAVE_BALANCES.path,
                ROUTING_CONSTANTS.CREATE_LEAVE_COMP_OFF.path,
                ROUTING_CONSTANTS.CREATE_LEAVE_ACCRUAL_POLICY.path,
                ROUTING_CONSTANTS.ACCRUAL_POLICY.path,
                ROUTING_CONSTANTS.LEAVE_CONTROL.path,
                ROUTING_CONSTANTS.LEAVE_PROGRAM.path,
                ROUTING_CONSTANTS.CREATE_MATERNITY_LEAVE_POLICY.path,
                ROUTING_CONSTANTS.CREATE_LEAVE_PROGRAM.path,
                ROUTING_CONSTANTS.ASSIGN_LEAVE_PROGRAM.path,
                ROUTING_CONSTANTS.LEAVE_ACCRUAL.path,
                ROUTING_CONSTANTS.APPLY_PROXY_LEAVE_BY_ADMIN.path,
                ROUTING_CONSTANTS.ADMIN_LEAVE_HISTORY.path,
                ROUTING_CONSTANTS.ADMIN_LEAVE_BALANCE_VIEW.path,
                ROUTING_CONSTANTS.ADMIN_COMP_OFF_SUMMARY_VIEW.path,
                ROUTING_CONSTANTS.VIEW_SHIFTS.path,
                ROUTING_CONSTANTS.DEFINE_PATTERN.path,
                ROUTING_CONSTANTS.DEFINE_SHIFTS.path,
                ROUTING_CONSTANTS.PROCESS_RESULT_DETAILS.path,
                ROUTING_CONSTANTS.COMP_OFF_REPORT.path,
                ROUTING_CONSTANTS.UPDATE_ENCASHMENT_STATUS.path,
                ROUTING_CONSTANTS.EMPLOYEE_ENCASHMENT_REPORT.path,
                ROUTING_CONSTANTS.LEAVE_PROGRAM_DETAILS_REPORT.path,
                ROUTING_CONSTANTS.PENDING_LEAVE_REQUESTS_REPORT.path,
                ROUTING_CONSTANTS.PROCESS_RESULTS.path,
                ROUTING_CONSTANTS.LEAVE_BALANCE_REPORT.path,
                ROUTING_CONSTANTS.UPLOAD_PATTERN.path,
                ROUTING_CONSTANTS.UPLOAD_LOPS.path,
                ROUTING_CONSTANTS.VIEW_PATTERN.path,
                CUSTOM_REPORTS.REPORT4.path,
                ROUTING_CONSTANTS.CREATE_EMPLOYEE_TAX_DATA.path,
                ROUTING_CONSTANTS.VIEW_EMPLOYEE_TAX_DATA.path,
                ROUTING_CONSTANTS.ONE_TIME_DEDUCTION_EARNING.path,
                ROUTING_CONSTANTS.CREATE_GENERAL_DEDUCTIONS.path,
                ROUTING_CONSTANTS.SEARCH_GENERAL_DEDUCTIONS.path,
                ROUTING_CONSTANTS.CREATE_PT_SLAB.path,
                ROUTING_CONSTANTS.CREATE_LWF_SLAB.path,
                ROUTING_CONSTANTS.PAY_GROUP_DASHBOARD.path,
                ROUTING_CONSTANTS.ASSIGN_PAY_GROUP.path,
                ROUTING_CONSTANTS.PAYROLL_CONTROLS.path,
                ROUTING_CONSTANTS.SEARCH_SUB_SECTION.path,
                ROUTING_CONSTANTS.CREATE_SUB_SECTION.path,
                ROUTING_CONSTANTS.CREATE_PAY_GROUP.path,
                ROUTING_CONSTANTS.ADD_EMP_COMP.path,
                ROUTING_CONSTANTS.RUN_PROCESS.path,
                ROUTING_CONSTANTS.UPLOAD_PFPTLWFESI.path,
                ROUTING_CONSTANTS.UPLOAD_ONE_TIME_EARNING_DEDUCTIONS.path,
                ROUTING_CONSTANTS.SEARCH_IT_SECTION.path,
                ROUTING_CONSTANTS.CREATE_IT_SECTION.path,
                ROUTING_CONSTANTS.APPLY_PROXY_LOAN_BY_ADMIN.path,
                ROUTING_CONSTANTS.SECTION_80G.path,
                ROUTING_CONSTANTS.EMPLOYEE_COMPENSATION_REPORT.path,
                ROUTING_CONSTANTS.PAYSLIP_TEMPLATES.path,
                ROUTING_CONSTANTS.UPLOAD_TDS.path,
                ROUTING_CONSTANTS.PAYROLL_DASHBOARD.path,
                ROUTING_CONSTANTS.PAYROLL_PROCESS_STAGE1_SALARY_SHEET.path,
                ROUTING_CONSTANTS.PAYROLL_PROCESS_STAGE3_SALARY_SHEET.path
              ]}>
              <LeftSidebar
                // companyId={
                //   location.pathname.toLowerCase() ===
                //     '/CreateCompany'.toLowerCase()
                //     ? new URLSearchParams(location.search).get('id')
                //     : null
                // }
                showHeader={true}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path={ROUTING_CONSTANTS.CREATE_COMPANY.path}>
                      <PermissionChecker
                        Component={CreateCompany}
                        permId={ROUTING_CONSTANTS.CREATE_COMPANY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.DESIGNATION_DASHBOARD.path}>
                      <PermissionChecker
                        Component={Designation}
                        permId={ROUTING_CONSTANTS.DESIGNATION_DASHBOARD.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_DESIGNATION.path}>
                      <PermissionChecker
                        Component={CreateDesignation}
                        permId={ROUTING_CONSTANTS.ADD_DESIGNATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.DEPARTMENT_DASHBOARD.path}>
                      <PermissionChecker
                        Component={Department}
                        permId={ROUTING_CONSTANTS.DEPARTMENT_DASHBOARD.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_DEPARTMENT.path}>
                      <PermissionChecker
                        Component={CreateDepartment}
                        permId={ROUTING_CONSTANTS.ADD_DEPARTMENT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LOCATION_DASHBOARD.path}>
                      <PermissionChecker
                        Component={Location}
                        permId={ROUTING_CONSTANTS.LOCATION_DASHBOARD.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_LOCATION.path}>
                      <PermissionChecker
                        Component={CreateLocation}
                        permId={ROUTING_CONSTANTS.ADD_LOCATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.USERID_SETUP.path}>
                      <PermissionChecker
                        Component={CreateUserId}
                        permId={ROUTING_CONSTANTS.USERID_SETUP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.AUTO_NUMBERING_SETUP.path}>
                      <PermissionChecker
                        Component={AutoNumberingSetup}
                        permId={ROUTING_CONSTANTS.AUTO_NUMBERING_SETUP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_EMERGENCY_CONTACT.path}>
                      <PermissionChecker
                        Component={AddEmergencyContact}
                        permId={ROUTING_CONSTANTS.ADD_EMERGENCY_CONTACT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_PHONE_AND_EMAIL.path}>
                      <PermissionChecker
                        Component={AddPhoneAndEmailAddress}
                        permId={ROUTING_CONSTANTS.ADD_PHONE_AND_EMAIL.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.ADD_CERTIFICATE_OR_LICENSE.path}>
                      <PermissionChecker
                        Component={AddCertificateLicense}
                        permId={ROUTING_CONSTANTS.ADD_CERTIFICATE_OR_LICENSE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_ADDRESS.path}>
                      <PermissionChecker
                        Component={AddAddress}
                        permId={ROUTING_CONSTANTS.HIRE_PROCESS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.HIRE_PROCESS.path}>
                      <PermissionChecker
                        Component={Hire}
                        permId={ROUTING_CONSTANTS.HIRE_PROCESS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.HIRE_PROCESS_VIEW.path}>
                      <PermissionChecker
                        Component={HireProcessView}
                        permId={ROUTING_CONSTANTS.HIRE_PROCESS_VIEW.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ACTIONS.path}>
                      <PermissionChecker
                        Component={Actions}
                        permId={ROUTING_CONSTANTS.ACTIONS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.SEARCH_ACTION_REASON.path}>
                      <PermissionChecker
                        Component={ActionsAndReasons}
                        permId={ROUTING_CONSTANTS.SEARCH_ACTION_REASON.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_ACTION.path}>
                      <PermissionChecker
                        Component={CreateAction}
                        permId={ROUTING_CONSTANTS.CREATE_ACTION.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.EARNING_DEDUCTION_CODES.path}>
                      <PermissionChecker
                        Component={EarningDeductionCode}
                        permId={ROUTING_CONSTANTS.EARNING_DEDUCTION_CODES.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.CREATE_EARNING_DEDUCTION_CODES.path
                      }>
                      <PermissionChecker
                        Component={CreateEarningDeductionCodes}
                        permId={
                          ROUTING_CONSTANTS.CREATE_EARNING_DEDUCTION_CODES.id
                        }
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.DEFINE_COMPENSATION_STRUCTURE.path
                      }>
                      <PermissionChecker
                        Component={DefineCompensationStructure}
                        permId={
                          ROUTING_CONSTANTS.DEFINE_COMPENSATION_STRUCTURE.id
                        }
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.SEARCH_COMPENSATION_STRUCTURE.path
                      }>
                      <PermissionChecker
                        Component={SearchCompensationStructure}
                        permId={
                          ROUTING_CONSTANTS.SEARCH_COMPENSATION_STRUCTURE.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PAY_CALENDAR.path}>
                      <PermissionChecker
                        Component={PayCalendar}
                        permId={ROUTING_CONSTANTS.PAY_CALENDAR.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ASSIGN_PAY_CALENDAR.path}>
                      <PermissionChecker
                        Component={AssignPayCalendar}
                        permId={ROUTING_CONSTANTS.ASSIGN_PAY_CALENDAR.id}
                      />
                    </Route>

                    <Route path={ROUTING_CONSTANTS.CREATE_PAY_CALENDAR.path}>
                      <PermissionChecker
                        Component={CreatePayCalendar}
                        permId={ROUTING_CONSTANTS.CREATE_PAY_CALENDAR.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.EMPLOYEE_PAYMENT_OPTIONS.path}>
                      <PermissionChecker
                        Component={EmployeePaymentOptions}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_PAYMENT_OPTIONS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LOAN_MANAGEMENT_SETUP.path}>
                      <PermissionChecker
                        Component={LoanManagementSetup}
                        permId={ROUTING_CONSTANTS.LOAN_MANAGEMENT_SETUP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_ACTION_REASON.path}>
                      <PermissionChecker
                        Component={CreateActionReason}
                        permId={ROUTING_CONSTANTS.CREATE_ACTION_REASON.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.VIEW_NATIONAL_ID.path}>
                      <PermissionChecker
                        Component={NationalID}
                        permId={ROUTING_CONSTANTS.VIEW_NATIONAL_ID.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_NATIONAL_ID.path}>
                      <PermissionChecker
                        Component={CreateNationalID}
                        permId={ROUTING_CONSTANTS.ADD_NATIONAL_ID.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_WORK_EXPERIENCE.path}>
                      <PermissionChecker
                        Component={AddWorkExperience}
                        permId={ROUTING_CONSTANTS.ADD_NATIONAL_ID.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_ITEM_CATALOGUE.path}>
                      <PermissionChecker
                        Component={createItemcatalogue}
                        permId={ROUTING_CONSTANTS.CREATE_ITEM_CATALOGUE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_DEPENDENTS.path}>
                      <PermissionChecker
                        Component={CreateDependants}
                        permId={ROUTING_CONSTANTS.ADD_DEPENDENTS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.ADD_EDUCATIONAL_DETAILS.path}>
                      <PermissionChecker
                        Component={AddEducation}
                        permId={ROUTING_CONSTANTS.ADD_EDUCATIONAL_DETAILS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.ITEM_CATALOGUE_DASHBOARD.path}>
                      <PermissionChecker
                        Component={itemCatalogue}
                        permId={ROUTING_CONSTANTS.ITEM_CATALOGUE_DASHBOARD.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.MODULE_ACCESS.path}>
                      <PermissionChecker
                        Component={ModuleAccess}
                        permId={ROUTING_CONSTANTS.MODULE_ACCESS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.BANK.path}>
                      <PermissionChecker
                        Component={Bank}
                        permId={ROUTING_CONSTANTS.BANK.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_BANK.path}>
                      <PermissionChecker
                        Component={CreateBank}
                        permId={ROUTING_CONSTANTS.CREATE_BANK.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_LOCATION.path}>
                      <PermissionChecker
                        Component={UploadLocation}
                        permId={ROUTING_CONSTANTS.UPLOAD_LOCATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_RESULTS.path}>
                      <PermissionChecker
                        Component={uploadResults}
                        permId={ROUTING_CONSTANTS.UPLOAD_RESULTS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_USER_ID.path}>
                      <PermissionChecker
                        Component={CreateEmployeeUserId}
                        permId={ROUTING_CONSTANTS.CREATE_USER_ID.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.VIEW_EMPLOYEES.path}>
                      <PermissionChecker
                        Component={Employees}
                        permId={ROUTING_CONSTANTS.VIEW_EMPLOYEES.id}
                      />
                    </Route>

                    <Route path={ROUTING_CONSTANTS.ACTIVE_INACTIVE.path}>
                      <PermissionChecker
                        Component={ActiveInActive}
                        permId={ROUTING_CONSTANTS.ACTIVE_INACTIVE.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.RESET_PASSWORD_BY_ADMIN.path}>
                      <PermissionChecker
                        Component={ResetPasswordByAdmin}
                        permId={ROUTING_CONSTANTS.RESET_PASSWORD_BY_ADMIN.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.CREATE_USER_CREDENTIALS.path}>
                      <PermissionChecker
                        Component={CreateUserCredentials}
                        permId={ROUTING_CONSTANTS.CREATE_USER_CREDENTIALS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PASSWORD_RULES.path}>
                      <PermissionChecker
                        Component={PasswordRules}
                        permId={ROUTING_CONSTANTS.PASSWORD_RULES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.VIEW_EMPLOYEE_DETAILS.path}>
                      <PermissionChecker
                        Component={EmployeeDetails}
                        permId={ROUTING_CONSTANTS.VIEW_EMPLOYEE_DETAILS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ORG_STRUCTURE.path}>
                      <PermissionChecker
                        Component={OrgStructure}
                        permId={ROUTING_CONSTANTS.ORG_STRUCTURE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_SOURCE_BANK.path}>
                      <PermissionChecker
                        Component={CreateSourceBank}
                        permId={ROUTING_CONSTANTS.CREATE_SOURCE_BANK.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.SOURCE_BANK.path}>
                      <PermissionChecker
                        Component={DashboardSourceBank}
                        permId={ROUTING_CONSTANTS.SOURCE_BANK.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ORG_CHART_SETUP.path}>
                      <PermissionChecker
                        Component={OrgChartSetup}
                        permId={ROUTING_CONSTANTS.ORG_CHART_SETUP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.BANK_BRANCHES.path}>
                      <PermissionChecker
                        Component={BankBranches}
                        permId={ROUTING_CONSTANTS.BANK_BRANCHES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_BANK_BRANCHES.path}>
                      <PermissionChecker
                        Component={CreateBankBranches}
                        permId={ROUTING_CONSTANTS.CREATE_BANK_BRANCHES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_EMPLOYEES.path}>
                      <PermissionChecker
                        Component={UploadBulkEmployees}
                        permId={ROUTING_CONSTANTS.UPLOAD_EMPLOYEES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_DEPARTMENT.path}>
                      <PermissionChecker
                        Component={UploadDepartment}
                        permId={ROUTING_CONSTANTS.UPLOAD_DEPARTMENT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_DESIGNATION.path}>
                      <PermissionChecker
                        Component={UploadDesignation}
                        permId={ROUTING_CONSTANTS.UPLOAD_DESIGNATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_NATIONAL_ID.path}>
                      <PermissionChecker
                        Component={UploadEmployeeNationalID}
                        permId={ROUTING_CONSTANTS.UPLOAD_NATIONAL_ID.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_ADDRESS.path}>
                      <PermissionChecker
                        Component={UploadEmployeeAddress}
                        permId={ROUTING_CONSTANTS.UPLOAD_ADDRESS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_PHONE.path}>
                      <PermissionChecker
                        Component={UploadPhone}
                        permId={ROUTING_CONSTANTS.UPLOAD_PHONE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_EMAIL_ID.path}>
                      <PermissionChecker
                        Component={UploadEmailID}
                        permId={ROUTING_CONSTANTS.UPLOAD_EMAIL_ID.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.UPLOAD_EMERGENCY_CONTACT.path}>
                      <PermissionChecker
                        Component={UploadEmergencyContact}
                        permId={ROUTING_CONSTANTS.UPLOAD_EMERGENCY_CONTACT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_DEPENDANTS.path}>
                      <PermissionChecker
                        Component={UploadDependants}
                        permId={ROUTING_CONSTANTS.UPLOAD_DEPENDANTS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_BENEFICIARY.path}>
                      <PermissionChecker
                        Component={UploadBeneficiary}
                        permId={ROUTING_CONSTANTS.UPLOAD_BENEFICIARY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_WORK_EXPERIENCE.path}>
                      <PermissionChecker
                        Component={UploadWorkExperience}
                        permId={ROUTING_CONSTANTS.UPLOAD_WORK_EXPERIENCE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_DOCUMENT.path}>
                      <PermissionChecker
                        Component={UploadDocument}
                        permId={ROUTING_CONSTANTS.UPLOAD_DOCUMENT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_CERTIFICATE.path}>
                      <PermissionChecker
                        Component={UploadCertificate}
                        permId={ROUTING_CONSTANTS.UPLOAD_CERTIFICATE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_LICENSE.path}>
                      <PermissionChecker
                        Component={UploadLicense}
                        permId={ROUTING_CONSTANTS.UPLOAD_LICENSE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_JOB_BAND.path}>
                      <PermissionChecker
                        Component={CreateJobBand}
                        permId={ROUTING_CONSTANTS.CREATE_JOB_BAND.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.JOB_BAND.path}>
                      <PermissionChecker
                        Component={JobBand}
                        permId={ROUTING_CONSTANTS.JOB_BAND.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_JOB_GRADE.path}>
                      <PermissionChecker
                        Component={UploadJobGrade}
                        permId={ROUTING_CONSTANTS.UPLOAD_JOB_GRADE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_JOB_BAND.path}>
                      <PermissionChecker
                        Component={UploadJobBands}
                        permId={ROUTING_CONSTANTS.UPLOAD_JOB_BAND.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.MANAGER_CHANGE_UPLOAD.path}>
                      <PermissionChecker
                        Component={ManagerChangeUpload}
                        permId={ROUTING_CONSTANTS.MANAGER_CHANGE_UPLOAD.id}
                      />
                    </Route>

                    <Route path={ROUTING_CONSTANTS.CREATE_JOB_GRADE.path}>
                      <PermissionChecker
                        Component={CreateJobGrade}
                        permId={ROUTING_CONSTANTS.CREATE_JOB_GRADE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.JOB_GRADE.path}>
                      <PermissionChecker
                        Component={JobGrade}
                        permId={ROUTING_CONSTANTS.JOB_GRADE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.EMPLOYEE_INFO_HISTORY.path}>
                      <PermissionChecker
                        Component={EmployeeInfoHistory}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_INFO_HISTORY.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS
                          .EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.path
                      }>
                      <PermissionChecker
                        Component={EmployeeDependantOrBeneficiaryDetails}
                        permId={
                          ROUTING_CONSTANTS
                            .EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.NATIONAL_ID_REPORT.path}>
                      <PermissionChecker
                        Component={NationalIDReport}
                        permId={ROUTING_CONSTANTS.NATIONAL_ID_REPORT.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.EMPLOYEE_DETAILS_REPORT.path}>
                      <PermissionChecker
                        Component={EmployeeDetailReports}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_DETAILS_REPORT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.DEPARTMENT_REPORT.path}>
                      <PermissionChecker
                        Component={DepartmentReport}
                        permId={ROUTING_CONSTANTS.DEPARTMENT_REPORT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.DOWNLOAD_RESULTS.path}>
                      <PermissionChecker
                        Component={downloadResults}
                        permId={ROUTING_CONSTANTS.DOWNLOAD_RESULTS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.EDIT_BIOGRAPHICAL_DETAILS.path}>
                      <PermissionChecker
                        Component={EditBiographicalDetails}
                        permId={ROUTING_CONSTANTS.EDIT_BIOGRAPHICAL_DETAILS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.EMERGENCY_CONTACT_REPORT.path}>
                      <PermissionChecker
                        Component={EmergencyContactReport}
                        permId={ROUTING_CONSTANTS.EMERGENCY_CONTACT_REPORT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_COMPANY_POLICY.path}>
                      <PermissionChecker
                        Component={UploadCompanyPolicy}
                        permId={ROUTING_CONSTANTS.UPLOAD_COMPANY_POLICY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PUBLISH_ANNOUNCEMENT.path}>
                      <PermissionChecker
                        Component={PublishAnnouncement}
                        permId={ROUTING_CONSTANTS.PUBLISH_ANNOUNCEMENT.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS
                          .EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path
                      }>
                      <PermissionChecker
                        Component={EmployeeCertificateOrLicenseReports}
                        permId={
                          ROUTING_CONSTANTS
                            .EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.EMPLOYEE_ROLES_REPORT.path}>
                      <PermissionChecker
                        Component={EmployeeRolesReport}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_ROLES_REPORT.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.MY_PEERS.path}>
                      <PermissionChecker
                        Component={MyPeers}
                        permId={ROUTING_CONSTANTS.MY_PEERS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.SUBMIT_RESIGNATION.path}>
                      <PermissionChecker
                        Component={ResignationDetails}
                        permId={ROUTING_CONSTANTS.SUBMIT_RESIGNATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.EMPLOYEE_TIMELINE.path}>
                      <PermissionChecker
                        Component={EmployeeTimeline}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_TIMELINE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_LETTERS.path}>
                      <PermissionChecker
                        Component={CreateLetters}
                        permId={ROUTING_CONSTANTS.CREATE_LETTERS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LETTERS.path}>
                      <PermissionChecker
                        Component={Letters}
                        permId={ROUTING_CONSTANTS.LETTERS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.GENERATE_LETTERS.path}>
                      <PermissionChecker
                        Component={GenetateLetter}
                        permId={ROUTING_CONSTANTS.GENERATE_LETTERS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.EMPLOYEE_HIRE_SEPARATION.path}>
                      <PermissionChecker
                        Component={EmployeeHireSeparation}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_HIRE_SEPARATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PROBATION_CONFIRMATION.path}>
                      <PermissionChecker
                        Component={ProbationConfirmation}
                        permId={ROUTING_CONSTANTS.PROBATION_CONFIRMATION.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.EMPLOYEE_MISSING_INFO_REPORT.path
                      }>
                      <PermissionChecker
                        Component={EmployeeMissingInfoReport}
                        permId={
                          ROUTING_CONSTANTS.EMPLOYEE_MISSING_INFO_REPORT.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.EMPLOYEE_BLOOD_GROUP.path}>
                      <PermissionChecker
                        Component={EmployeeBloodGroup}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_BLOOD_GROUP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.EXIT_CONTROLS.path}>
                      <PermissionChecker
                        Component={ExitControl}
                        permId={ROUTING_CONSTANTS.EXIT_CONTROLS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PROBATION_PERIOD_SETUP.path}>
                      <PermissionChecker
                        Component={ProbationPeriodSetup}
                        permId={ROUTING_CONSTANTS.PROBATION_PERIOD_SETUP.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.HOLIDAY_CALENDER_MASTER.path}>
                      <PermissionChecker
                        Component={HolidayCalendarMaster}
                        permId={ROUTING_CONSTANTS.HOLIDAY_CALENDER_MASTER.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.HOLIDAY_CALENDER.path}>
                      <PermissionChecker
                        Component={HolidayCalendar}
                        permId={ROUTING_CONSTANTS.HOLIDAY_CALENDER.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.HOLIDAY_CALENDER_CONFIGURATION.path
                      }>
                      <PermissionChecker
                        Component={HolidayCalendarConfiguration}
                        permId={
                          ROUTING_CONSTANTS.HOLIDAY_CALENDER_CONFIGURATION.id
                        }
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.EMPLOYEE_PRIOR_WORK_EXPERIENCE_REPORT
                          .path
                      }>
                      <PermissionChecker
                        Component={EmployeePriorWorkExperienceReport}
                        permId={
                          ROUTING_CONSTANTS
                            .EMPLOYEE_PRIOR_WORK_EXPERIENCE_REPORT.id
                        }
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.CREATE_NOTIFICATION_TEMPLATE.path
                      }>
                      <PermissionChecker
                        Component={CreateNotificationTemplate}
                        permId={
                          ROUTING_CONSTANTS.CREATE_NOTIFICATION_TEMPLATE.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.USER_MAPPING.path}>
                      <PermissionChecker
                        Component={UserMapping}
                        permId={ROUTING_CONSTANTS.USER_MAPPING.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PROBATION_CONTROL.path}>
                      <PermissionChecker
                        Component={ProbationControls}
                        permId={ROUTING_CONSTANTS.PROBATION_CONTROL.id}
                      />
                    </Route>

                    <Route path={ROUTING_CONSTANTS.CREATE_ROLES.path}>
                      <PermissionChecker
                        Component={CreateRoles}
                        permId={ROUTING_CONSTANTS.CREATE_ROLES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.TRANSACTION_SUMMARY.path}>
                      <PermissionChecker
                        Component={TransactionSummary}
                        permId={ROUTING_CONSTANTS.TRANSACTION_SUMMARY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.MY_TRANSACTION_SUMMARY.path}>
                      <PermissionChecker
                        Component={MyTransactionSummary}
                        permId={ROUTING_CONSTANTS.MY_TRANSACTION_SUMMARY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_TYPES.path}>
                      <PermissionChecker
                        Component={LeaveTypes}
                        permId={ROUTING_CONSTANTS.LEAVE_TYPES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_LEAVE_TYPES.path}>
                      <PermissionChecker
                        Component={CreateLeaveTypes}
                        permId={ROUTING_CONSTANTS.CREATE_LEAVE_TYPES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_ACCUMULATION.path}>
                      <PermissionChecker
                        Component={LeaveAccumulation}
                        permId={ROUTING_CONSTANTS.LEAVE_ACCUMULATION.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.CREATE_LEAVE_ACCUMULATION.path}>
                      <PermissionChecker
                        Component={CreateLeaveAccumulation}
                        permId={ROUTING_CONSTANTS.CREATE_LEAVE_ACCUMULATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_ENCASHMENT.path}>
                      <PermissionChecker
                        Component={LeaveEncashment}
                        permId={ROUTING_CONSTANTS.LEAVE_ENCASHMENT.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.CREATE_LEAVE_ENCASHMENT.path}>
                      <PermissionChecker
                        Component={CreateLeaveEncashment}
                        permId={ROUTING_CONSTANTS.CREATE_LEAVE_ENCASHMENT.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.LEAVE_POLICY_DEFINITION.path}>
                      <PermissionChecker
                        Component={LeavePolicyDefinition}
                        permId={ROUTING_CONSTANTS.LEAVE_POLICY_DEFINITION.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.CREATE_LEAVE_POLICY_DEFINITION.path
                      }>
                      <PermissionChecker
                        Component={CreateLeavePolicyDefinition}
                        permId={
                          ROUTING_CONSTANTS.CREATE_LEAVE_POLICY_DEFINITION.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_COMP_OFF.path}>
                      <PermissionChecker
                        Component={LeaveCompOff}
                        permId={ROUTING_CONSTANTS.LEAVE_COMP_OFF.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_LEAVE_BALANCES.path}>
                      <PermissionChecker
                        Component={UploadLeaveBalances}
                        permId={ROUTING_CONSTANTS.UPLOAD_LEAVE_BALANCES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_ACCRUAL.path}>
                      <PermissionChecker
                        Component={LeaveAccrual}
                        permId={ROUTING_CONSTANTS.LEAVE_ACCRUAL.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ACCRUAL_POLICY.path}>
                      <PermissionChecker
                        Component={AccrualPolicy}
                        permId={ROUTING_CONSTANTS.ACCRUAL_POLICY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_CONTROL.path}>
                      <PermissionChecker
                        Component={LeaveControl}
                        permId={ROUTING_CONSTANTS.LEAVE_CONTROL.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_PROGRAM.path}>
                      <PermissionChecker
                        Component={LeaveProgram}
                        permId={ROUTING_CONSTANTS.LEAVE_PROGRAM.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.CREATE_LEAVE_ACCRUAL_POLICY.path}>
                      <PermissionChecker
                        Component={CreateLeaveAccrualPolicy}
                        permId={
                          ROUTING_CONSTANTS.CREATE_LEAVE_ACCRUAL_POLICY.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_BALANCE_REPORT.path}>
                      <PermissionChecker
                        Component={LeaveBalanceReport}
                        permId={ROUTING_CONSTANTS.LEAVE_BALANCE_REPORT.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.PENDING_LEAVE_REQUESTS_REPORT.path
                      }>
                      <PermissionChecker
                        Component={PendingLeaveRequestsReport}
                        permId={
                          ROUTING_CONSTANTS.PENDING_LEAVE_REQUESTS_REPORT.id
                        }
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.LEAVE_PROGRAM_DETAILS_REPORT.path
                      }>
                      <PermissionChecker
                        Component={LeaveProgramDetailsReport}
                        permId={
                          ROUTING_CONSTANTS.LEAVE_PROGRAM_DETAILS_REPORT.id
                        }
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.CREATE_MATERNITY_LEAVE_POLICY.path
                      }>
                      <PermissionChecker
                        Component={CreateMaternityLeavePolicy}
                        permId={
                          ROUTING_CONSTANTS.CREATE_MATERNITY_LEAVE_POLICY.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_LEAVE_COMP_OFF.path}>
                      <PermissionChecker
                        Component={CreateCompOff}
                        permId={ROUTING_CONSTANTS.CREATE_LEAVE_COMP_OFF.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_LEAVE_PROGRAM.path}>
                      <PermissionChecker
                        Component={CreateLeaveProgram}
                        permId={ROUTING_CONSTANTS.CREATE_LEAVE_PROGRAM.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ASSIGN_LEAVE_PROGRAM.path}>
                      <PermissionChecker
                        Component={AssignLeaveProgram}
                        permId={ROUTING_CONSTANTS.ASSIGN_LEAVE_PROGRAM.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.APPLY_PROXY_LEAVE_BY_ADMIN.path}>
                      <PermissionChecker
                        Component={ApplyProxyLeaveByAdmin}
                        permId={ROUTING_CONSTANTS.APPLY_PROXY_LEAVE_BY_ADMIN.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADMIN_LEAVE_HISTORY.path}>
                      <PermissionChecker
                        Component={ViewAdminLeaveHistory}
                        permId={ROUTING_CONSTANTS.ADMIN_LEAVE_HISTORY.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.ADMIN_LEAVE_BALANCE_VIEW.path}>
                      <PermissionChecker
                        Component={AdminLeaveBalanceView}
                        permId={ROUTING_CONSTANTS.ADMIN_LEAVE_BALANCE_VIEW.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.DEFINE_PATTERN.path}>
                      <PermissionChecker
                        Component={DefinePattern}
                        permId={ROUTING_CONSTANTS.DEFINE_PATTERN.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.ADMIN_COMP_OFF_SUMMARY_VIEW.path}>
                      <PermissionChecker
                        Component={AdminCompOffSummaryView}
                        permId={
                          ROUTING_CONSTANTS.ADMIN_COMP_OFF_SUMMARY_VIEW.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.DEFINE_SHIFTS.path}>
                      <PermissionChecker
                        Component={DefineShifts}
                        permId={ROUTING_CONSTANTS.DEFINE_SHIFTS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.VIEW_SHIFTS.path}>
                      <PermissionChecker
                        Component={AdminViewShifts}
                        permId={ROUTING_CONSTANTS.VIEW_SHIFTS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.VIEW_PATTERN.path}>
                      <PermissionChecker
                        Component={SearchDefinePattern}
                        permId={ROUTING_CONSTANTS.VIEW_PATTERN.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_PATTERN.path}>
                      <PermissionChecker
                        Component={UploadPattern}
                        permId={ROUTING_CONSTANTS.UPLOAD_PATTERN.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_LOPS.path}>
                      <PermissionChecker
                        Component={UploadLOPs}
                        permId={ROUTING_CONSTANTS.UPLOAD_LOPS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_PFPTLWFESI.path}>
                      <PermissionChecker
                        Component={UploadPFPTLWFESI}
                        permId={ROUTING_CONSTANTS.UPLOAD_PFPTLWFESI.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.UPLOAD_ONE_TIME_EARNING_DEDUCTIONS
                          .path
                      }>
                      <PermissionChecker
                        Component={UploadOneTimeEarningDeductions}
                        permId={
                          ROUTING_CONSTANTS.UPLOAD_ONE_TIME_EARNING_DEDUCTIONS
                            .id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPLOAD_TDS.path}>
                      <PermissionChecker
                        Component={UploadTDS}
                        permId={ROUTING_CONSTANTS.UPLOAD_TDS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PROCESS_RESULTS.path}>
                      <PermissionChecker
                        Component={ProcessResults}
                        permId={ROUTING_CONSTANTS.PROCESS_RESULTS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PROCESS_RESULT_DETAILS.path}>
                      <PermissionChecker
                        Component={ProcessResultDetails}
                        permId={ROUTING_CONSTANTS.PROCESS_RESULT_DETAILS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.COMP_OFF_REPORT.path}>
                      <PermissionChecker
                        Component={CompOffReport}
                        permId={ROUTING_CONSTANTS.COMP_OFF_REPORT.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.EMPLOYEE_ENCASHMENT_REPORT.path}>
                      <PermissionChecker
                        Component={EmployeeEncashmentReport}
                        permId={ROUTING_CONSTANTS.EMPLOYEE_ENCASHMENT_REPORT.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.UPDATE_ENCASHMENT_STATUS.path}>
                      <PermissionChecker
                        Component={UpdateEncashmentStatus}
                        permId={ROUTING_CONSTANTS.UPDATE_ENCASHMENT_STATUS.id}
                      />
                    </Route>
                    <Route path={CUSTOM_REPORTS.REPORT4.path}>
                      <PermissionChecker
                        Component={CustomReport}
                        permId={CUSTOM_REPORTS.REPORT4.id}
                        isCustomReport={true}
                        reportId={CUSTOM_REPORTS.REPORT4.rid}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.CREATE_EMPLOYEE_TAX_DATA.path}>
                      <PermissionChecker
                        Component={EmployeeTaxData}
                        permId={ROUTING_CONSTANTS.CREATE_EMPLOYEE_TAX_DATA.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.VIEW_EMPLOYEE_TAX_DATA.path}>
                      <PermissionChecker
                        Component={SearchEmployeeTaxData}
                        permId={ROUTING_CONSTANTS.VIEW_EMPLOYEE_TAX_DATA.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.ONE_TIME_DEDUCTION_EARNING.path}>
                      <PermissionChecker
                        Component={CreateOneTimeEarningDeduction}
                        permId={ROUTING_CONSTANTS.ONE_TIME_DEDUCTION_EARNING.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.SEARCH_GENERAL_DEDUCTIONS.path}>
                      <PermissionChecker
                        Component={SearchGeneralDeductions}
                        permId={ROUTING_CONSTANTS.SEARCH_GENERAL_DEDUCTIONS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.CREATE_GENERAL_DEDUCTIONS.path}>
                      <PermissionChecker
                        Component={CreateGeneralDeductions}
                        permId={ROUTING_CONSTANTS.CREATE_GENERAL_DEDUCTIONS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_PT_SLAB.path}>
                      <PermissionChecker
                        Component={CreatePTSlab}
                        permId={ROUTING_CONSTANTS.CREATE_PT_SLAB.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_LWF_SLAB.path}>
                      <PermissionChecker
                        Component={CreateLWFSlab}
                        permId={ROUTING_CONSTANTS.CREATE_LWF_SLAB.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PAY_GROUP_DASHBOARD.path}>
                      <PermissionChecker
                        Component={PayGroup}
                        permId={ROUTING_CONSTANTS.PAY_GROUP_DASHBOARD.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_PAY_GROUP.path}>
                      <PermissionChecker
                        Component={CreatePayGroup}
                        permId={ROUTING_CONSTANTS.CREATE_PAY_GROUP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ASSIGN_PAY_GROUP.path}>
                      <PermissionChecker
                        Component={AssignPayGroup}
                        permId={ROUTING_CONSTANTS.ASSIGN_PAY_GROUP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PAYROLL_CONTROLS.path}>
                      <PermissionChecker
                        Component={PayrollControls}
                        permId={ROUTING_CONSTANTS.PAYROLL_CONTROLS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_SUB_SECTION.path}>
                      <PermissionChecker
                        Component={CreateSubSection}
                        permId={ROUTING_CONSTANTS.CREATE_SUB_SECTION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.SEARCH_SUB_SECTION.path}>
                      <PermissionChecker
                        Component={SubSection}
                        permId={ROUTING_CONSTANTS.SEARCH_SUB_SECTION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ADD_EMP_COMP.path}>
                      <PermissionChecker
                        Component={AddEmployeeCompensation}
                        permId={ROUTING_CONSTANTS.ADD_EMP_COMP.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.RUN_PROCESS.path}>
                      <PermissionChecker
                        Component={RunProcess}
                        permId={ROUTING_CONSTANTS.RUN_PROCESS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.SEARCH_IT_SECTION.path}>
                      <PermissionChecker
                        Component={SearchITSection}
                        permId={ROUTING_CONSTANTS.SEARCH_IT_SECTION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_IT_SECTION.path}>
                      <PermissionChecker
                        Component={CreateITSection}
                        permId={ROUTING_CONSTANTS.CREATE_IT_SECTION.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.APPLY_PROXY_LOAN_BY_ADMIN.path}>
                      <PermissionChecker
                        Component={ApplyProxyLoanByAdmin}
                        permId={ROUTING_CONSTANTS.APPLY_PROXY_LOAN_BY_ADMIN.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.SECTION_80G.path}>
                      <PermissionChecker
                        Component={Section80G}
                        permId={ROUTING_CONSTANTS.SECTION_80G.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PAYSLIP_TEMPLATES.path}>
                      <PermissionChecker Component={CreatePaySlipTemplates} />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.EMPLOYEE_COMPENSATION_REPORT.path
                      }>
                      <PermissionChecker
                        Component={EmployeeCompensationReport}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PAYROLL_DASHBOARD.path}>
                      <PermissionChecker Component={PayrollDashboard} />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.PAYROLL_PROCESS_STAGE1_SALARY_SHEET
                          .path
                      }>
                      <PermissionChecker
                        Component={PayrollProcessStage1SalarySheet}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.PAYROLL_PROCESS_STAGE3_SALARY_SHEET
                          .path
                      }>
                      <PermissionChecker
                        Component={PayrollProcessStage3SalarySheet}
                        permId={
                          ROUTING_CONSTANTS.PAYROLL_PROCESS_STAGE3_SALARY_SHEET
                            .id
                        }
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route path={[ROUTING_CONSTANTS.PRIVACY_POLICY.path]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path={ROUTING_CONSTANTS.PRIVACY_POLICY.path}
                      component={PrivacyPolicy}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route
              path={[
                ROUTING_CONSTANTS.DASHBOARD.path,
                ROUTING_CONSTANTS.CUSTOMER_DASHBOARD.path,
                ROUTING_CONSTANTS.CREATE_CUSTOMER.path,
                ROUTING_CONSTANTS.COMPANY_DASHBOARD.path,
                ROUTING_CONSTANTS.ROLES_DASHBOARD.path,
                ROUTING_CONSTANTS.ASSIGN_UNASSIGN_PERMISSIONS.path,
                ROUTING_CONSTANTS.UPDATE_OR_DELETE_ID.path,
                ROUTING_CONSTANTS.NOTIFICATION_TEMPLATE.path
              ]}>
              <LeftSidebar companyId={null} showHeader={true}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path={ROUTING_CONSTANTS.DASHBOARD.path}
                      component={Dashboard}></Route>
                    <Route path={ROUTING_CONSTANTS.CUSTOMER_DASHBOARD.path}>
                      <PermissionChecker
                        Component={Customer}
                        permId={ROUTING_CONSTANTS.CUSTOMER_DASHBOARD.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CREATE_CUSTOMER.path}>
                      <PermissionChecker
                        Component={CreateCustomer}
                        permId={ROUTING_CONSTANTS.CREATE_CUSTOMER.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.COMPANY_DASHBOARD.path}>
                      <PermissionChecker
                        Component={Company}
                        permId={ROUTING_CONSTANTS.CREATE_CUSTOMER.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.ROLES_DASHBOARD.path}>
                      <PermissionChecker
                        Component={RolesAndPermissions}
                        permId={ROUTING_CONSTANTS.ROLES_DASHBOARD.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.UPDATE_OR_DELETE_ID.path}>
                      <PermissionChecker
                        Component={UpdateEmployeeId}
                        permId={ROUTING_CONSTANTS.UPDATE_OR_DELETE_ID.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.ASSIGN_UNASSIGN_PERMISSIONS.path}>
                      <PermissionChecker
                        Component={Permissions}
                        permId={
                          ROUTING_CONSTANTS.ASSIGN_UNASSIGN_PERMISSIONS.id
                        }
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.NOTIFICATION_TEMPLATE.path}>
                      <PermissionChecker
                        Component={NotificationTemplate}
                        permId={ROUTING_CONSTANTS.NOTIFICATION_TEMPLATE.id}
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={[
                ROUTING_CONSTANTS.MY_PROFILE.path,
                ROUTING_CONSTANTS.MY_TIMELINE.path
                // ROUTING_CONSTANTS.MY_COMPENSATION.path
              ]}>
              <LeftSidebar
                showSidebar={false}
                showUserSidebar={true}
                headerType="MyProfile">
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path={ROUTING_CONSTANTS.MY_PROFILE.path}>
                      <PermissionChecker
                        Component={MyProfile}
                        permId={ROUTING_CONSTANTS.MY_PROFILE.id}
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={[
                ROUTING_CONSTANTS.MY_TEAM.path,
                // LEAVE and TIME are not yet defined
                ROUTING_CONSTANTS.LEAVE.path,
                ROUTING_CONSTANTS.TIME.path,
                ROUTING_CONSTANTS.PENDING_APPROVALS.path,
                ROUTING_CONSTANTS.INITIATE_ACTIONS.path,
                ROUTING_CONSTANTS.MANAGER_TRANSACTION_SUMMARY.path
              ]}>
              <LeftSidebar
                showSidebar={false}
                showUserSidebar={true}
                headerType="MyTeam">
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path={ROUTING_CONSTANTS.MY_TEAM.path}>
                      <PermissionChecker
                        Component={MyTeam}
                        permId={ROUTING_CONSTANTS.MY_TEAM.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.PENDING_APPROVALS.path}>
                      <PermissionChecker
                        Component={PendingApprovals}
                        permId={ROUTING_CONSTANTS.PENDING_APPROVALS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.INITIATE_ACTIONS.path}>
                      <PermissionChecker
                        Component={InitiateActions}
                        permId={ROUTING_CONSTANTS.INITIATE_ACTIONS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.MANAGER_TRANSACTION_SUMMARY.path}>
                      <PermissionChecker
                        Component={ManagerTransactionSummary}
                        permId={
                          ROUTING_CONSTANTS.MANAGER_TRANSACTION_SUMMARY.id
                        }
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={[
                ROUTING_CONSTANTS.MY_LEAVES.path,
                ROUTING_CONSTANTS.LEAVE_HISTORY.path,
                ROUTING_CONSTANTS.TEAM_LEAVE_CALENDAR.path,
                ROUTING_CONSTANTS.CANCEL_OR_ADJUST_LEAVE.path,
                ROUTING_CONSTANTS.COMP_OFF_ELIGIBILITY.path,
                ROUTING_CONSTANTS.LEAVE_BALANCE_DETAILS.path,
                ROUTING_CONSTANTS.APPLY_LEAVE.path
              ]}>
              <LeftSidebar
                showSidebar={false}
                showUserSidebar={true}
                headerType="MyLeaves">
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path={ROUTING_CONSTANTS.MY_LEAVES.path}>
                      <PermissionChecker
                        Component={MyLeaves}
                        permId={ROUTING_CONSTANTS.MY_LEAVES.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_HISTORY.path}>
                      <PermissionChecker
                        Component={LeaveHistory}
                        permId={ROUTING_CONSTANTS.LEAVE_HISTORY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.TEAM_LEAVE_CALENDAR.path}>
                      <PermissionChecker
                        Component={TeamLeaveCalendar}
                        permId={ROUTING_CONSTANTS.TEAM_LEAVE_CALENDAR.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.APPLY_LEAVE.path}>
                      <PermissionChecker
                        Component={ApplyLeave}
                        permId={ROUTING_CONSTANTS.APPLY_LEAVE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.CANCEL_OR_ADJUST_LEAVE.path}>
                      <PermissionChecker
                        Component={CancelOrAdjustLeave}
                        permId={ROUTING_CONSTANTS.CANCEL_OR_ADJUST_LEAVE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.COMP_OFF_ELIGIBILITY.path}>
                      <PermissionChecker
                        Component={CompOffEligibility}
                        permId={ROUTING_CONSTANTS.COMP_OFF_ELIGIBILITY.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.LEAVE_BALANCE_DETAILS.path}>
                      <PermissionChecker
                        Component={LeaveBalance}
                        permId={ROUTING_CONSTANTS.LEAVE_BALANCE_DETAILS.id}
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={[
                ROUTING_CONSTANTS.PENDING_LEAVE_APPROVALS.path,
                ROUTING_CONSTANTS.MANAGER_LEAVE_HISTORY.path,
                ROUTING_CONSTANTS.TEAM_LEAVE_BALANCE_DETAILS.path,
                ROUTING_CONSTANTS.TEAM_LEAVE_BALANCE.path,
                ROUTING_CONSTANTS.APPLY_PROXY_LEAVE.path,
                ROUTING_CONSTANTS.APPROVE_OR_REJECT_LEAVE_REQUEST.path,
                ROUTING_CONSTANTS.MANAGER_TEAM_LEAVE_CALENDAR.path,
                ROUTING_CONSTANTS.LEAVE_ADJUSTMENT_REQUESTS.path,
                ROUTING_CONSTANTS.TEAM_COMP_OFF_SUMMARY.path
              ]}>
              <LeftSidebar
                showSidebar={false}
                showUserSidebar={true}
                headerType="Leaves">
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path={ROUTING_CONSTANTS.PENDING_LEAVE_APPROVALS.path}>
                      <PermissionChecker
                        Component={PendingLeaveApprovals}
                        permId={ROUTING_CONSTANTS.PENDING_LEAVE_APPROVALS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.MANAGER_LEAVE_HISTORY.path}>
                      <PermissionChecker
                        Component={ManagerLeaveHistory}
                        permId={ROUTING_CONSTANTS.MANAGER_LEAVE_HISTORY.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.TEAM_LEAVE_BALANCE_DETAILS.path}>
                      <PermissionChecker
                        Component={TeamLeaveBalanceDetails}
                        permId={ROUTING_CONSTANTS.TEAM_LEAVE_BALANCE_DETAILS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.TEAM_LEAVE_BALANCE.path}>
                      <PermissionChecker
                        Component={TeamLeaveBalance}
                        permId={ROUTING_CONSTANTS.TEAM_LEAVE_BALANCE.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.APPLY_PROXY_LEAVE.path}>
                      <PermissionChecker
                        Component={ApplyProxyLeaveByManager}
                        permId={ROUTING_CONSTANTS.APPLY_PROXY_LEAVE.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.APPROVE_OR_REJECT_LEAVE_REQUEST.path
                      }>
                      <PermissionChecker
                        Component={ApproveOrRejectLeaveRequest}
                        permId={
                          ROUTING_CONSTANTS.APPROVE_OR_REJECT_LEAVE_REQUEST.id
                        }
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.MANAGER_TEAM_LEAVE_CALENDAR.path}>
                      <PermissionChecker
                        Component={ManagerTeamLeaveCalendar}
                        permId={
                          ROUTING_CONSTANTS.MANAGER_TEAM_LEAVE_CALENDAR.id
                        }
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.LEAVE_ADJUSTMENT_REQUESTS.path}>
                      <PermissionChecker
                        Component={LeaveAdjustmentRequests}
                        permId={ROUTING_CONSTANTS.LEAVE_ADJUSTMENT_REQUESTS.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.TEAM_COMP_OFF_SUMMARY.path}>
                      <PermissionChecker
                        Component={TeamCompOffSummary}
                        permId={ROUTING_CONSTANTS.TEAM_COMP_OFF_SUMMARY.id}
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route
              path={[
                ROUTING_CONSTANTS.PENDING_LOAN_APPROVALS.path,
                ROUTING_CONSTANTS.APPROVE_OR_REJECT_LOAN_REQUEST.path
              ]}>
              <LeftSidebar
                showSidebar={false}
                showUserSidebar={true}
                headerType="TeamSalary">
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path={ROUTING_CONSTANTS.PENDING_LOAN_APPROVALS.path}>
                      <PermissionChecker
                        Component={PendingLoanApprovals}
                        permId={ROUTING_CONSTANTS.PENDING_LOAN_APPROVALS.id}
                      />
                    </Route>
                    <Route
                      path={
                        ROUTING_CONSTANTS.APPROVE_OR_REJECT_LOAN_REQUEST.path
                      }>
                      <PermissionChecker
                        Component={ApproveOrRejectLoanRequest}
                        permId={
                          ROUTING_CONSTANTS.APPROVE_OR_REJECT_LOAN_REQUEST.id
                        }
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={[
                ROUTING_CONSTANTS.MY_COMPENSATION.path,
                ROUTING_CONSTANTS.INVESTMENT_DECLARATION.path,
                ROUTING_CONSTANTS.POI.path,
                ROUTING_CONSTANTS.SALARY_REVISION.path,
                ROUTING_CONSTANTS.MY_PAYSLIPS.path,
                ROUTING_CONSTANTS.VIEW_COMPENSATION_HISTORY.path,
                ROUTING_CONSTANTS.MY_COMPENSATION_TEMPLATE.path
              ]}>
              <LeftSidebar
                showSidebar={false}
                showUserSidebar={true}
                headerType="MyCompensation">
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path={ROUTING_CONSTANTS.MY_COMPENSATION.path}>
                      <PermissionChecker
                        Component={MyCompensation}
                        permId={ROUTING_CONSTANTS.MY_COMPENSATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.INVESTMENT_DECLARATION.path}>
                      <PermissionChecker
                        Component={InvestmentDeclaration}
                        permId={ROUTING_CONSTANTS.INVESTMENT_DECLARATION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.POI.path}>
                      <PermissionChecker
                        Component={POI}
                        permId={ROUTING_CONSTANTS.POI.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.SALARY_REVISION.path}>
                      <PermissionChecker
                        Component={SalaryRevision}
                        permId={ROUTING_CONSTANTS.SALARY_REVISION.id}
                      />
                    </Route>
                    <Route path={ROUTING_CONSTANTS.MY_PAYSLIPS.path}>
                      <PermissionChecker
                        Component={MY_PAYSLIPS}
                        permId={ROUTING_CONSTANTS.MY_PAYSLIPS.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.VIEW_COMPENSATION_HISTORY.path}>
                      <PermissionChecker
                        Component={ViewCompensationHistory}
                        permId={ROUTING_CONSTANTS.VIEW_COMPENSATION_HISTORY.id}
                      />
                    </Route>
                    <Route
                      path={ROUTING_CONSTANTS.MY_COMPENSATION_TEMPLATE.path}>
                      <PermissionChecker
                        Component={MY_COMPENSATION_TEMPLATE}
                        permId={ROUTING_CONSTANTS.MY_COMPENSATION_TEMPLATE.id}
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route path={[ROUTING_CONSTANTS.TEAM_COMPENSATION.path]}>
              <LeftSidebar
                showSidebar={false}
                showUserSidebar={true}
                headerType="TeamCompensation">
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path={ROUTING_CONSTANTS.TEAM_COMPENSATION.path}>
                      <PermissionChecker
                        Component={TeamCompensation}
                        permId={ROUTING_CONSTANTS.TEAM_COMPENSATION.id}
                      />
                    </Route>
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route path={[ROUTING_CONSTANTS.LOGIN.path]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path={ROUTING_CONSTANTS.LOGIN.path}
                      component={Login}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route path={[ROUTING_CONSTANTS.RESET_PASSWORD.path]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path={ROUTING_CONSTANTS.RESET_PASSWORD.path}
                      component={ResetPassword}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route path={[ROUTING_CONSTANTS.FORGOT_PASSWORD.path]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path={ROUTING_CONSTANTS.FORGOT_PASSWORD.path}
                      component={ForgotPassword}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => ({
  user: state.Auth.user,
  view: state.Auth.view,
  modules: state.Auth.modules,
  customReports: state.Auth.customReports
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
