import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from 'actions';
import { checkPermission } from 'utils/commonUtils';
import { ROUTING_CONSTANTS } from 'constants/constants';

const TeamCompensationHeaderMenu = (props) => {
  const { user, view, modules } = props;
  const location = useLocation();

  return (
    <>
      <div className=" app-header-menu pt-3  justify-content-center">
        <List className="nav-line d-flex flex-wrap nav-tabs-second">
          {checkPermission({
            permId: ROUTING_CONSTANTS.TEAM_COMPENSATION.id,
            permissions: user.permissions,
            view: view,
            modules: modules
          }) && (
            <ListItem
              size="small"
              component={NavLink}
              to={ROUTING_CONSTANTS.TEAM_COMPENSATION.path}
              className="btn-pill"
              button
              disableRipple
              selected={
                ROUTING_CONSTANTS.TEAM_COMPENSATION.path === location.pathname
              }>
              <span className="font-size-sm font-weight-normal">
                Apply Proxy Loan
              </span>
            </ListItem>
          )}
        </List>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
  modules: state.Auth.modules
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: (accessToken) => dispatch(logoutUser(accessToken))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamCompensationHeaderMenu);
