import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Popover, Button, List, ListItem } from '@material-ui/core';

import { NavLink, useLocation } from 'react-router-dom';
import {
  FUNCTIONALITY_PERMISSION_CONSTANTS,
  ROUTING_CONSTANTS
} from 'constants/constants';
import { checkPermission } from 'utils/commonUtils';
import { connect } from 'react-redux';

const HeaderMenu = (props) => {
  const { user, view, modules } = props;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadAnchor, setUploadAnchor] = useState(null);
  const [reportAnchor, setReportAnchor] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [processAnchor, setProcessAnchor] = useState(null);
  const [payrollAnchor, setPayrollAnchor] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadClick = (event) => {
    setUploadAnchor(event.currentTarget);
  };

  const handleUploadClose = () => {
    setUploadAnchor(null);
  };

  const handleReportClick = (event) => {
    setReportAnchor(event.currentTarget);
  };

  const handleReportClose = () => {
    setReportAnchor(null);
  };

  const handlePayrollClick = (event) => {
    setPayrollAnchor(event.currentTarget);
  };

  const handlePayrollClose = () => {
    setPayrollAnchor(null);
  };

  //process click and close events
  const handleProcessClick = (event) => {
    setProcessAnchor(event.currentTarget);
  };

  const handleProcessClose = () => {
    setProcessAnchor(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mega-menu-popover' : undefined;

  const uploadOpen = Boolean(uploadAnchor);
  const uploadId = uploadOpen ? 'upload-menu-popover' : undefined;

  const reportOpen = Boolean(reportAnchor);
  const reportId = reportOpen ? 'report-menu-popover' : undefined;

  const leaveOpen = Boolean(anchorElMenu);
  const leaveId = leaveOpen ? 'leave-menu-popover' : undefined;

  const processOpen = Boolean(processAnchor);
  const processId = processOpen ? 'report-menu-popover' : undefined;

  const payrollOpen = Boolean(payrollAnchor);
  const payrollId = payrollOpen ? 'payroll-menu-popover' : undefined;

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <div className="app-header-menu">
        {checkPermission({
          permId:
            FUNCTIONALITY_PERMISSION_CONSTANTS.VIEW_WORKFORCE_MANAGEMENT.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <Button size="small" onClick={handleClick} className="btn-pill  ">
            Workforce Management
          </Button>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <div className="popover-custom-xxl p-0">
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-first p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.HIRE_PROCESS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      component="a"
                      selected={
                        ROUTING_CONSTANTS.HIRE_PROCESS.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.HIRE_PROCESS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Hire Process</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.VIEW_NATIONAL_ID.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.VIEW_NATIONAL_ID.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.VIEW_NATIONAL_ID.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>National ID</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_ADDRESS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_ADDRESS.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADD_ADDRESS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Address</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_EMERGENCY_CONTACT.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_EMERGENCY_CONTACT.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADD_EMERGENCY_CONTACT.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Emergency Contact</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_DEPENDENTS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_DEPENDENTS.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADD_DEPENDENTS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Dependants</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_WORK_EXPERIENCE.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_WORK_EXPERIENCE.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADD_WORK_EXPERIENCE.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Work Experience</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_PHONE_AND_EMAIL.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_PHONE_AND_EMAIL.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADD_PHONE_AND_EMAIL.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Phone & Email</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_CERTIFICATE_OR_LICENSE.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_CERTIFICATE_OR_LICENSE.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADD_CERTIFICATE_OR_LICENSE.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Certificate / License</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.VIEW_EMPLOYEES.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.VIEW_EMPLOYEES.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.VIEW_EMPLOYEES.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Employee Directory</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ORG_STRUCTURE.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ORG_STRUCTURE.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ORG_STRUCTURE.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Org Structure</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_EDUCATIONAL_DETAILS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_EDUCATIONAL_DETAILS.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADD_EDUCATIONAL_DETAILS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Education Details</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-success p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.DEPARTMENT_DASHBOARD.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      component="a"
                      selected={
                        ROUTING_CONSTANTS.DEPARTMENT_DASHBOARD.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.DEPARTMENT_DASHBOARD.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Department</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LOCATION_DASHBOARD.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.LOCATION_DASHBOARD.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.LOCATION_DASHBOARD.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Location</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.DESIGNATION_DASHBOARD.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.DESIGNATION_DASHBOARD.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.DESIGNATION_DASHBOARD.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Designation</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.SOURCE_BANK.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.SOURCE_BANK.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.SOURCE_BANK.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Source Bank</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.BANK_BRANCHES.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.BANK_BRANCHES.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.BANK_BRANCHES.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Bank Branches</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.BANK.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.BANK.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.BANK.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Bank</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.JOB_BAND.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.JOB_BAND.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.JOB_BAND.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Job Band</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.JOB_GRADE.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.JOB_GRADE.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.JOB_GRADE.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Job Grade</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PUBLISH_ANNOUNCEMENT.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.PUBLISH_ANNOUNCEMENT.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.PUBLISH_ANNOUNCEMENT.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Publish Company News</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LETTERS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.LETTERS.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.LETTERS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Letters</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.EXIT_CONTROLS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.EXIT_CONTROLS.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.EXIT_CONTROLS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Exit Controls</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PROBATION_CONTROL.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.PROBATION_CONTROL.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.PROBATION_CONTROL.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Probation Control</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PROBATION_PERIOD_SETUP.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.PROBATION_PERIOD_SETUP.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.PROBATION_PERIOD_SETUP.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Probation Period Setup</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.HOLIDAY_CALENDER_MASTER.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.HOLIDAY_CALENDER_MASTER.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.HOLIDAY_CALENDER_MASTER.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Holiday Calendar Master</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.HOLIDAY_CALENDER.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.HOLIDAY_CALENDER.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.HOLIDAY_CALENDER.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Holiday Calendar</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid item xs={4}>
                <List component="div" className="nav-neutral-danger p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.USERID_SETUP.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.USERID_SETUP.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.USERID_SETUP.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>UserID Setup</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.AUTO_NUMBERING_SETUP.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.AUTO_NUMBERING_SETUP.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.AUTO_NUMBERING_SETUP.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Auto Numbering Setup</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.UPDATE_OR_DELETE_ID.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.UPDATE_OR_DELETE_ID.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.UPDATE_OR_DELETE_ID.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Update/Delete ID</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.CREATE_USER_ID.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.CREATE_USER_ID.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.CREATE_USER_ID.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Create UserID</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.TRANSACTION_SUMMARY.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.TRANSACTION_SUMMARY.path ===
                        location.pathname + location.search
                      }
                      href={ROUTING_CONSTANTS.TRANSACTION_SUMMARY.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Transaction Summary</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </div>
        </Popover>
        {checkPermission({
          permId: FUNCTIONALITY_PERMISSION_CONSTANTS.VIEW_LEAVE_MANAGEMENT.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <Button onClick={handleClickMenu} size="small" className="btn-pill  ">
            Leave Management
          </Button>
        )}
        <Popover
          id={leaveId}
          open={leaveOpen}
          anchorEl={anchorElMenu}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <div className="popover-custom-xxl p-0">
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-first p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_TYPES.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.LEAVE_TYPES.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.LEAVE_TYPES.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Types</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_CONTROL.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      component="a"
                      selected={
                        ROUTING_CONSTANTS.LEAVE_CONTROL.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.LEAVE_CONTROL.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Controls</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_ACCUMULATION.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.LEAVE_ACCUMULATION.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.LEAVE_ACCUMULATION.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Accumulation</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ACCRUAL_POLICY.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      onClick={handleCloseMenu}
                      component={NavLink}
                      selected={
                        ROUTING_CONSTANTS.ACCRUAL_POLICY.path ===
                        location.pathname
                      }
                      to={ROUTING_CONSTANTS.ACCRUAL_POLICY.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Accrual Policy</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.CREATE_MATERNITY_LEAVE_POLICY.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      onClick={handleCloseMenu}
                      component={NavLink}
                      selected={
                        ROUTING_CONSTANTS.CREATE_MATERNITY_LEAVE_POLICY.path ===
                        location.pathname
                      }
                      to={ROUTING_CONSTANTS.CREATE_MATERNITY_LEAVE_POLICY.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Maternity Leave Policy</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_ENCASHMENT.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.LEAVE_ENCASHMENT.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.LEAVE_ENCASHMENT.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Encashment</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_PROGRAM_DASHBOARD.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      onClick={handleCloseMenu}
                      component={NavLink}
                      selected={
                        ROUTING_CONSTANTS.LEAVE_PROGRAM_DASHBOARD.path ===
                        location.pathname
                      }
                      to={ROUTING_CONSTANTS.LEAVE_PROGRAM_DASHBOARD.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Program</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_POLICY_DEFINITION.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.LEAVE_POLICY_DEFINITION.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.LEAVE_POLICY_DEFINITION.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Policy Definition</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_COMP_OFF.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.LEAVE_COMP_OFF.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.LEAVE_COMP_OFF.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Comp Off</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ASSIGN_LEAVE_PROGRAM.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ASSIGN_LEAVE_PROGRAM.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ASSIGN_LEAVE_PROGRAM.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Assign Leave Program</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.APPLY_PROXY_LEAVE_BY_ADMIN.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.APPLY_PROXY_LEAVE_BY_ADMIN.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.APPLY_PROXY_LEAVE_BY_ADMIN.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Apply Proxy Leave</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADMIN_LEAVE_HISTORY.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADMIN_LEAVE_HISTORY.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADMIN_LEAVE_HISTORY.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave History</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADMIN_LEAVE_BALANCE_VIEW.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADMIN_LEAVE_BALANCE_VIEW.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADMIN_LEAVE_BALANCE_VIEW.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Balance</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADMIN_COMP_OFF_SUMMARY_VIEW.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ADMIN_COMP_OFF_SUMMARY_VIEW.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ADMIN_COMP_OFF_SUMMARY_VIEW.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Team Comp Off Summary</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.VIEW_SHIFTS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.VIEW_SHIFTS.path === location.pathname
                      }
                      href={ROUTING_CONSTANTS.VIEW_SHIFTS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>View Shifts</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.VIEW_PATTERN.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.VIEW_PATTERN.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.VIEW_PATTERN.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Define Pattern</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-first p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LEAVE_ACCRUAL.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.LEAVE_ACCRUAL.path ===
                        location.pathname
                      }
                      onClick={handleCloseMenu}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.LEAVE_ACCRUAL.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Leave Accrual</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.UPDATE_ENCASHMENT_STATUS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.UPDATE_ENCASHMENT_STATUS.path ===
                        location.pathname
                      }
                      onClick={handleCloseMenu}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.UPDATE_ENCASHMENT_STATUS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Update Encashment Status</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </div>
        </Popover>
        {checkPermission({
          permId: FUNCTIONALITY_PERMISSION_CONSTANTS.VIEW_PAYROLL.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <Button
            size="small"
            onClick={handlePayrollClick}
            className="btn-pill  ">
            Payroll
          </Button>
        )}
        <Popover
          id={payrollId}
          open={payrollOpen}
          anchorEl={payrollAnchor}
          onClose={handlePayrollClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <div className="popover-custom-xxl p-0">
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-first p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.VIEW_EMPLOYEE_TAX_DATA.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.VIEW_EMPLOYEE_TAX_DATA.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.VIEW_EMPLOYEE_TAX_DATA.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Employee Tax Data</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.EARNING_DEDUCTION_CODES.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.EARNING_DEDUCTION_CODES.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.EARNING_DEDUCTION_CODES.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Earning Deduction Codes</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.DEFINE_COMPENSATION_STRUCTURE.id,
                    permissions: user.permissions,
                    view: view
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.SEARCH_COMPENSATION_STRUCTURE.path ===
                        location.pathname
                      }
                      href={
                        ROUTING_CONSTANTS.SEARCH_COMPENSATION_STRUCTURE.path
                      }>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Compensation Structure</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.SEARCH_GENERAL_DEDUCTIONS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.SEARCH_GENERAL_DEDUCTIONS.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.SEARCH_GENERAL_DEDUCTIONS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>General Deductions</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ONE_TIME_DEDUCTION_EARNING.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.ONE_TIME_DEDUCTION_EARNING.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.ONE_TIME_DEDUCTION_EARNING.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>One Time Earning/Deduction</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.EARNING_DEDUCTION_CODES.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.PAY_CALENDAR.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.PAY_CALENDAR.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Pay Calendar</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ASSIGN_PAY_CALENDAR.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.ASSIGN_PAY_CALENDAR.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.ASSIGN_PAY_CALENDAR.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Assign Pay Calendar</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.CREATE_PT_SLAB.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      component="a"
                      button
                      selected={
                        ROUTING_CONSTANTS.CREATE_PT_SLAB.path ===
                        location.pathname
                      }
                      href={ROUTING_CONSTANTS.CREATE_PT_SLAB.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Create PT Slab</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PAY_GROUP_DASHBOARD.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.PAY_GROUP_DASHBOARD.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.PAY_GROUP_DASHBOARD.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Pay Group</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ASSIGN_PAY_GROUP.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.ASSIGN_PAY_GROUP.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.ASSIGN_PAY_GROUP.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Assign Pay Group</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.CREATE_LWF_SLAB.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.CREATE_LWF_SLAB.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.CREATE_LWF_SLAB.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>LWF Slab</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.ADD_EMP_COMP.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.ADD_EMP_COMP.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.ADD_EMP_COMP.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Add Employee Compensation</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.EMPLOYEE_PAYMENT_OPTIONS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.EMPLOYEE_PAYMENT_OPTIONS.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.EMPLOYEE_PAYMENT_OPTIONS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Employee Payment Options</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.LOAN_MANAGEMENT_SETUP.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.LOAN_MANAGEMENT_SETUP.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.LOAN_MANAGEMENT_SETUP.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Loan Management Setup</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PAYROLL_CONTROLS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.PAYROLL_CONTROLS.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.PAYROLL_CONTROLS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Payroll Controls</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.SEARCH_SUB_SECTION.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.SEARCH_SUB_SECTION.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.SEARCH_SUB_SECTION.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Sub-Section</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-first p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.APPLY_PROXY_LOAN_BY_ADMIN.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.APPLY_PROXY_LOAN_BY_ADMIN.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.APPLY_PROXY_LOAN_BY_ADMIN.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Apply Proxy Loan</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PAYSLIP_TEMPLATES.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.PAYSLIP_TEMPLATES.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.PAYSLIP_TEMPLATES.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Pay Slip Templates</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PAYROLL_DASHBOARD.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.PAYROLL_DASHBOARD.path ===
                        location.pathname
                      }
                      onClick={handlePayrollClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.PAYROLL_DASHBOARD.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Payroll Dashboard</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </div>
        </Popover>
        {checkPermission({
          permId: FUNCTIONALITY_PERMISSION_CONSTANTS.VIEW_UPLOADS.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <Button onClick={handleUploadClick} className="btn-pill">
            Upload
          </Button>
        )}
        <Popover
          id={uploadId}
          open={uploadOpen}
          anchorEl={uploadAnchor}
          onClose={handleUploadClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <div className="popover-custom-xxl p-0">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="px-3 mt-5 text-uppercase pb-2 text-primary font-weight-bold font-size-sm">
                  Workforce Management
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_RESULTS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_RESULTS.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_RESULTS.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Results</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_DEPARTMENT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_DEPARTMENT.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_DEPARTMENT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Department</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_EMPLOYEES.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_EMPLOYEES.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_EMPLOYEES.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Employees</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_DESIGNATION.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_DESIGNATION.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_DESIGNATION.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Designation</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_CERTIFICATE.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_CERTIFICATE.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_CERTIFICATE.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Certificate</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_LICENSE.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_LICENSE.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_LICENSE.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload License</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_DOCUMENT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_DOCUMENT.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_DOCUMENT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Document</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_NATIONAL_ID.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_NATIONAL_ID.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_NATIONAL_ID.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload National ID</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_ADDRESS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_ADDRESS.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_ADDRESS.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Address</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_LOCATION.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_LOCATION.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_LOCATION.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Location</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_PHONE.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_PHONE.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_PHONE.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Phone</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_JOB_GRADE.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_JOB_GRADE.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_JOB_GRADE.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Job Grade</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_JOB_BAND.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_JOB_BAND.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_JOB_BAND.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Job Band</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_COMPANY_POLICY.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_COMPANY_POLICY.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_COMPANY_POLICY.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Company Policy</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-success p-3">
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_EMAIL_ID.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_EMAIL_ID.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_EMAIL_ID.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Email ID</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_EMERGENCY_CONTACT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_EMERGENCY_CONTACT.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_EMERGENCY_CONTACT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Emergency Contact</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_DEPENDANTS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_DEPENDANTS.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_DEPENDANTS.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Dependants</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_BENEFICIARY.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_BENEFICIARY.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_BENEFICIARY.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Beneficiary</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_WORK_EXPERIENCE.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_WORK_EXPERIENCE.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_WORK_EXPERIENCE.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Work Experience</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.MANAGER_CHANGE_UPLOAD.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.MANAGER_CHANGE_UPLOAD.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.MANAGER_CHANGE_UPLOAD.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Manager Change Upload</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
                <div className="px-3 mt-5 text-uppercase pb-2 text-primary font-weight-bold font-size-sm">
                  Leave Management
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_LEAVE_BALANCES.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_LEAVE_BALANCES.path ===
                            location.pathname
                          }
                          onClick={handleUploadClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.UPLOAD_LEAVE_BALANCES.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Leave Balances</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_PATTERN.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          component="a"
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_PATTERN.path ===
                            location.pathname
                          }
                          href={ROUTING_CONSTANTS.UPLOAD_PATTERN.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload Pattern</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_LOPS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          component="a"
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_LOPS.path ===
                            location.pathname
                          }
                          href={ROUTING_CONSTANTS.UPLOAD_LOPS.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload LOPs</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_PFPTLWFESI.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          component="a"
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_PFPTLWFESI.path ===
                            location.pathname
                          }
                          href={ROUTING_CONSTANTS.UPLOAD_PFPTLWFESI.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload PF PT LWF ESI</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS.UPLOAD_ONE_TIME_EARNING_DEDUCTIONS
                            .id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          component="a"
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_ONE_TIME_EARNING_DEDUCTIONS
                              .path === location.pathname
                          }
                          href={
                            ROUTING_CONSTANTS.UPLOAD_ONE_TIME_EARNING_DEDUCTIONS
                              .path
                          }>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload One Time Earning/Deductions</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.UPLOAD_TDS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          component="a"
                          button
                          selected={
                            ROUTING_CONSTANTS.UPLOAD_TDS.path ===
                            location.pathname
                          }
                          href={ROUTING_CONSTANTS.UPLOAD_TDS.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Upload TDS</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Popover>
        {checkPermission({
          permId: FUNCTIONALITY_PERMISSION_CONSTANTS.VIEW_REPORTS.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <Button onClick={handleReportClick} className="btn-pill">
            Reports
          </Button>
        )}
        <Popover
          id={reportId}
          open={reportOpen}
          anchorEl={reportAnchor}
          onClose={handleReportClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <div className="popover-custom-xxl p-0">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="px-3 mt-5 text-uppercase pb-2 text-primary font-weight-bold font-size-sm">
                  Workforce Management
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.DOWNLOAD_RESULTS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.DOWNLOAD_RESULTS.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.DOWNLOAD_RESULTS.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Download Results</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.NATIONAL_ID_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.NATIONAL_ID_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.NATIONAL_ID_REPORT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>National ID Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.EMPLOYEE_DETAILS_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMPLOYEE_DETAILS_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.EMPLOYEE_DETAILS_REPORT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Details Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.EMERGENCY_CONTACT_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMERGENCY_CONTACT_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.EMERGENCY_CONTACT_REPORT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Emergency Contact Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS.EMPLOYEE_MISSING_INFO_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMPLOYEE_MISSING_INFO_REPORT
                              .path === location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={
                            ROUTING_CONSTANTS.EMPLOYEE_MISSING_INFO_REPORT.path
                          }>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Missing Info Report</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS
                            .EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            `${ROUTING_CONSTANTS.EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.path}?type=Dependant` ===
                            location.pathname + location.search
                          }
                          to={`${ROUTING_CONSTANTS.EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.path}?type=Dependant`}
                          onClick={() => {
                            handleReportClose();
                            window.location.assign(
                              `${ROUTING_CONSTANTS.EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.path}?type=Dependant`
                            );
                          }}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Dependant Details</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.DEPARTMENT_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.DEPARTMENT_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.DEPARTMENT_REPORT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Department Wise Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.EMPLOYEE_HIRE_SEPARATION.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMPLOYEE_HIRE_SEPARATION.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.EMPLOYEE_HIRE_SEPARATION.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Hire Separation</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.PROBATION_CONFIRMATION.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.PROBATION_CONFIRMATION.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.PROBATION_CONFIRMATION.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Probation Confirmation</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.EMPLOYEE_BLOOD_GROUP.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMPLOYEE_BLOOD_GROUP.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.EMPLOYEE_BLOOD_GROUP.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Blood Group</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS
                            .EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            `${ROUTING_CONSTANTS.EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.path}?type=Beneficiary` ===
                            location.pathname + location.search
                          }
                          to={`${ROUTING_CONSTANTS.EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.path}?type=Beneficiary`}
                          onClick={() => {
                            handleReportClose();
                            window.location.assign(
                              `${ROUTING_CONSTANTS.EMPLOYEE_DEPENDANT_OR_BENEFICIARY_DETAILS.path}?type=Beneficiary`
                            );
                          }}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Beneficiary Details</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS
                            .EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            `${ROUTING_CONSTANTS.EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path}?type=Certificate` ===
                            location.pathname + location.search
                          }
                          to={`${ROUTING_CONSTANTS.EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path}?type=Certificate`}
                          onClick={() => {
                            handleReportClose();
                            window.location.assign(
                              `${ROUTING_CONSTANTS.EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path}?type=Certificate`
                            );
                          }}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Certificate Details</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS
                            .EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            `${ROUTING_CONSTANTS.EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path}?type=License` ===
                            location.pathname + location.search
                          }
                          to={`${ROUTING_CONSTANTS.EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path}?type=License`}
                          onClick={() => {
                            handleReportClose();
                            window.location.assign(
                              `${ROUTING_CONSTANTS.EMPLOYEE_CERTIFICATE_OR_LICENSE_REPORTS.path}?type=License`
                            );
                          }}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee License Details</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.EMPLOYEE_ROLES_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMPLOYEE_ROLES_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.EMPLOYEE_ROLES_REPORT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Roles Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS
                            .EMPLOYEE_PRIOR_WORK_EXPERIENCE_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS
                              .EMPLOYEE_PRIOR_WORK_EXPERIENCE_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={
                            ROUTING_CONSTANTS
                              .EMPLOYEE_PRIOR_WORK_EXPERIENCE_REPORT.path
                          }>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Prior Work Experience Report</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
                <div className="px-3 mt-5 text-uppercase pb-2 text-primary font-weight-bold font-size-sm">
                  Leave Management
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.LEAVE_BALANCE_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.LEAVE_BALANCE_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.LEAVE_BALANCE_REPORT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Leave Balance Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.EMPLOYEE_ENCASHMENT_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMPLOYEE_ENCASHMENT_REPORT
                              .path === location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={
                            ROUTING_CONSTANTS.EMPLOYEE_ENCASHMENT_REPORT.path
                          }>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Encashment Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId: ROUTING_CONSTANTS.COMP_OFF_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.COMP_OFF_REPORT.path ===
                            location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={ROUTING_CONSTANTS.COMP_OFF_REPORT.path}>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Comp Off Details Report</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS.LEAVE_PROGRAM_DETAILS_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.LEAVE_PROGRAM_DETAILS_REPORT
                              .path === location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={
                            ROUTING_CONSTANTS.LEAVE_PROGRAM_DETAILS_REPORT.path
                          }>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Leave Program Details Report</span>
                        </ListItem>
                      )}
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS.PENDING_LEAVE_REQUESTS_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.PENDING_LEAVE_REQUESTS_REPORT
                              .path === location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={
                            ROUTING_CONSTANTS.PENDING_LEAVE_REQUESTS_REPORT.path
                          }>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Pending Leave Requests Report</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
                <div className="px-3 mt-5 text-uppercase pb-2 text-primary font-weight-bold font-size-sm">
                  Pay Roll
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <List component="div" className="nav-neutral-first p-3">
                      {checkPermission({
                        permId:
                          ROUTING_CONSTANTS.EMPLOYEE_COMPENSATION_REPORT.id,
                        permissions: user.permissions,
                        view: view,
                        modules: modules
                      }) && (
                        <ListItem
                          button
                          selected={
                            ROUTING_CONSTANTS.EMPLOYEE_COMPENSATION_REPORT
                              .path === location.pathname
                          }
                          onClick={handleReportClose}
                          component={NavLink}
                          to={
                            ROUTING_CONSTANTS.EMPLOYEE_COMPENSATION_REPORT.path
                          }>
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={['fas', 'chevron-right']}
                              className="font-size-xs opacity-3"
                            />
                          </div>
                          <span>Employee Compensation Report</span>
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Popover>
        {checkPermission({
          permId: FUNCTIONALITY_PERMISSION_CONSTANTS.VIEW_PROCESSES.id,
          permissions: user.permissions,
          view: view,
          modules: modules
        }) && (
          <Button onClick={handleProcessClick} className="btn-pill">
            Process
          </Button>
        )}
        <Popover
          id={processId}
          open={processOpen}
          anchorEl={processAnchor}
          onClose={handleProcessClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          <div className="popover-custom-xxl p-0">
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <div className="divider-v divider-v-lg" />
                <List component="div" className="nav-neutral-first p-3">
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.PROCESS_RESULTS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.PROCESS_RESULTS.path ===
                        location.pathname
                      }
                      onClick={handleProcessClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.PROCESS_RESULTS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Process Results</span>
                    </ListItem>
                  )}
                  {checkPermission({
                    permId: ROUTING_CONSTANTS.RUN_PROCESS.id,
                    permissions: user.permissions,
                    view: view,
                    modules: modules
                  }) && (
                    <ListItem
                      button
                      selected={
                        ROUTING_CONSTANTS.RUN_PROCESS.path === location.pathname
                      }
                      onClick={handleProcessClose}
                      component={NavLink}
                      to={ROUTING_CONSTANTS.RUN_PROCESS.path}>
                      <div className="mr-2">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                      <span>Run Process</span>
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </div>
        </Popover>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.Auth.user,
  view: state.Auth.view,
  modules: state.Auth.modules
});

const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
