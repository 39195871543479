import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from 'actions';
import { checkPermission } from 'utils/commonUtils';
import { ROUTING_CONSTANTS } from 'constants/constants';

const UserHeaderMenu = (props) => {
  const { user, view, modules } = props;
  const location = useLocation();

  return (
    <>
      <div className=" app-header-menu pt-3  justify-content-center">
        <List className="nav-line d-flex  nav-tabs-second">
          {checkPermission({
            permId: ROUTING_CONSTANTS.MY_PROFILE.id,
            permissions: user.permissions,
            view: view,
            modules: modules
          }) && (
            <ListItem
              size="small"
              component={NavLink}
              selected={ROUTING_CONSTANTS.MY_PROFILE.path === location.pathname}
              to={ROUTING_CONSTANTS.MY_PROFILE.path}
              className="btn-pill"
              button
              disableRipple>
              <span className="font-size-sm font-weight-normal">
                My Profile
              </span>
            </ListItem>
          )}
          {checkPermission({
            permId: ROUTING_CONSTANTS.EMPLOYEE_TIMELINE.id,
            permissions: user.permissions,
            view: view,
            modules: modules
          }) && (
            <ListItem
              size="small"
              component={NavLink}
              to={ROUTING_CONSTANTS.EMPLOYEE_TIMELINE.path}
              className="btn-pill"
              button
              disableRipple
              selected={
                ROUTING_CONSTANTS.EMPLOYEE_TIMELINE.path === location.pathname
              }>
              <span className="font-size-sm font-weight-normal">
                {' '}
                My Timeline
              </span>
            </ListItem>
          )}
          {checkPermission({
            permId: ROUTING_CONSTANTS.ORG_STRUCTURE.id,
            permissions: user.permissions,
            view: view,
            modules: modules
          }) && (
            <ListItem
              size="small"
              component={NavLink}
              to={`${ROUTING_CONSTANTS.ORG_STRUCTURE.path}?userUUID=${user.uuid}`}
              className="btn-pill"
              button
              disableRipple
              selected={
                ROUTING_CONSTANTS.ORG_STRUCTURE.path === location.pathname
              }>
              <span className="font-size-sm font-weight-normal ">
                Org Structure
              </span>
            </ListItem>
          )}
          {checkPermission({
            permId: ROUTING_CONSTANTS.MY_PEERS.id,
            permissions: user.permissions,
            view: view,
            modules: modules
          }) && (
            <ListItem
              size="small"
              component={NavLink}
              to={ROUTING_CONSTANTS.MY_PEERS.path}
              className="btn-pill"
              button
              disableRipple
              selected={ROUTING_CONSTANTS.MY_PEERS.path === location.pathname}>
              <span className="font-size-sm font-weight-normal">My Peers</span>
            </ListItem>
          )}
          {checkPermission({
            permId: ROUTING_CONSTANTS.SUBMIT_RESIGNATION.id,
            permissions: user.permissions,
            view: view,
            modules: modules
          }) && (
            <ListItem
              size="small"
              component={NavLink}
              to={ROUTING_CONSTANTS.SUBMIT_RESIGNATION.path}
              className="btn-pill"
              button
              disableRipple
              selected={
                ROUTING_CONSTANTS.SUBMIT_RESIGNATION.path === location.pathname
              }>
              <span className="font-size-sm font-weight-normal">
                Submit Resignation
              </span>
            </ListItem>
          )}
          {checkPermission({
            permId: ROUTING_CONSTANTS.MY_TRANSACTION_SUMMARY.id,
            permissions: user.permissions,
            view: view,
            modules: modules
          }) && (
            <ListItem
              size="small"
              component={NavLink}
              to={ROUTING_CONSTANTS.MY_TRANSACTION_SUMMARY.path}
              className="btn-pill"
              button
              disableRipple
              selected={
                ROUTING_CONSTANTS.MY_TRANSACTION_SUMMARY.path ===
                location.pathname
              }>
              <span className="font-size-sm font-weight-normal">
                Transaction Summary
              </span>
            </ListItem>
          )}
        </List>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
  modules: state.Auth.modules
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: (accessToken) => dispatch(logoutUser(accessToken))
});
export default connect(mapStateToProps, mapDispatchToProps)(UserHeaderMenu);
