import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Badge, Menu, Button, Tooltip, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { logoutUser } from 'actions';
import { getInitials } from 'utils/commonUtils';
import { getBackgroundColorBasedOnCondition } from 'utils/commonUtils';
import ConfirmationAlert from 'components/ConfimationAlert/ConfirmationAlert';

const SidebarUserbox = (props) => {
  const { accessToken } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { logoutUser, user, view } = props;
  const [officialEmail, setOfficialEmail] = useState();
  const [displayConfirmModal, setDisplayConfirmModel] = useState(false);
  const [confirmAlertMessage, setConfirmAlertMessage] = useState('');

  useEffect(() => {
    const reqEmail = user.emails?.find(
      (emailData) => emailData?.type?.toLowerCase() === 'Official'.toLowerCase()
    );
    setOfficialEmail(reqEmail?.email);
  }, [user]);
  const handleCloseConfirmModal = () => {
    setDisplayConfirmModel(false);
  };
  const logout = () => {
    setDisplayConfirmModel(true);
    setConfirmAlertMessage('Log out');
  };
  const saveData = () => {
    logoutUser(accessToken);
    window.location.href = 'login';
    setDisplayConfirmModel(false);
  };
  return (
    <>
      <div className="app-sidebar--userbox">
        <Box className="card-tr-actions">
          <Button
            variant="text"
            onClick={handleClick}
            className="ml-2 p-0 d-30 border-0 btn-transition-none text-white-50"
            disableRipple>
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className="font-size-lg"
            />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={Boolean(anchorEl)}
            classes={{ list: 'p-0' }}
            onClose={handleClose}>
            <div className="dropdown-menu-lg overflow-hidden p-0">
              <div className="align-box-row align-items-center p-3">
                <div className="avatar-icon-wrapper avatar-icon-md">
                  <div className="avatar-icon rounded-circle">
                    {user.profilePic ? (
                      <img
                        className="img-fluid img-fit-container rounded-circle"
                        src={user.profilePic}
                        style={{ width: '150px', height: '140px' }}
                        alt="..."
                      />
                    ) : (
                      <div className="initials-avatar">
                        <div
                          className="rounded-circle overflow-hidden d-140 text-center font-weight-bold text-white-50 d-flex justify-content-center align-items-center fixedProfile"
                          style={{
                            backgroundColor: getBackgroundColorBasedOnCondition(
                              user.legalName
                            )
                          }}>
                          {getInitials(user.legalName)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pl-2">
                  <span className="font-weight-bold d-block text-capitalize">
                    {user.legalName}
                  </span>
                  <div className="badge badge-success border-0">Active</div>
                </div>
              </div>
              <Divider className="w-100" />
              <div className="d-flex py-3 justify-content-center">
                <div className="d-flex align-items-center">
                  <div>
                    <FontAwesomeIcon
                      icon={['far', 'user']}
                      className="font-size-xxl text-success"
                    />
                  </div>
                  <div className="pl-3 line-height-sm">
                    <b className="font-size-lg">14,596</b>
                    <span className="text-black-50 d-block">reports</span>
                  </div>
                </div>
              </div>
              <Divider className="w-100" />
              <div className="d-block rounded-bottom py-3 text-center">
                <Tooltip arrow title="Facebook">
                  <Button
                    size="large"
                    className="btn-facebook mx-1 p-0 d-40 font-size-lg text-white">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fab', 'facebook']} />
                    </span>
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Twitter">
                  <Button
                    size="large"
                    className="btn-twitter mx-1 p-0 d-40 font-size-lg text-white">
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fab', 'twitter']} />
                    </span>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Menu>
        </Box>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent=" "
            overlap="circle"
            classes={{ badge: 'bg-success badge-circle' }}>
            <div className="avatar-icon rounded-circle">
              {user.profilePic ? (
                <img
                  className="img-fluid img-fit-container rounded-sm"
                  src={user.profilePic}
                  alt="..."
                />
              ) : (
                <div
                  className="initials-avatar"
                  style={{
                    backgroundColor:
                      user.username === 'admin'
                        ? getBackgroundColorBasedOnCondition(user.username)
                        : getBackgroundColorBasedOnCondition(user.legalName)
                  }}>
                  {user.username === 'admin'
                    ? getInitials(user.username)
                    : getInitials(user.legalName)}
                </div>
              )}
            </div>
          </Badge>
        </div>
        {view === 'Employee' ? (
          <div className="my-2 userbox-details">
            <span className="text-capitalize">{user.designationName}</span>
          </div>
        ) : (
          ''
        )}
        {view === 'Admin' ? (
          <div className="my-3 userbox-details">
            <span className="text-capitalize">{user.username}</span>
            <small className="d-block text-white-50">
              ({officialEmail ? officialEmail : 'Official Mail Id Not Added'})
            </small>
          </div>
        ) : (
          <div className=" userbox-details">
            <div className=" userbox-details">
              <span className="text-capitalize">
                {user.firstName +
                  ' ' +
                  (user.middleName === undefined ? '' : user.middleName) +
                  ' ' +
                  user.lastName}{' '}
              </span>
            </div>
            <div className="my-2 userbox-details">
              <span>
                {officialEmail ? officialEmail : 'Official Mail Id Not Added'}
              </span>
            </div>
          </div>
        )}
        {view === 'Admin' ? (
          <Button onClick={() => logout()} size="small" className="btn-userbox">
            Logout
          </Button>
        ) : (
          ''
        )}
      </div>
      <ConfirmationAlert
        showModal={displayConfirmModal}
        message={confirmAlertMessage}
        saveData={saveData}
        handleCloseConfirmModal={handleCloseConfirmModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
  view: state.Auth.view,
  accessToken: state.Auth.accessToken
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: (accessToken) => dispatch(logoutUser(accessToken))
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarUserbox);
