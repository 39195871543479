import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import clsx from 'clsx';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import { Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import { checkPermission } from 'utils/commonUtils';
import { FUNCTIONALITY_PERMISSION_CONSTANTS } from 'constants/constants';

const UserMenu = (props) => {
  const { user, view, modules } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(false);
  };
  const [collapsedLayoutOpen, setCollapsedLayoutOpen] = useState(false);
  const [hasManagerAccess, setManagerAccess] = useState(false);
  const [hasMyLeavesModule, setMyLeavesModule] = useState(false);
  const [hasMyProfileModule, setMyProfileModule] = useState(false);
  const [hasTeamLeavesModule, setTeamLeavesModule] = useState(false);
  const [hasMyCompensationModule, setMyCompensationModule] = useState(false);
  const [hasTeamSalaryModule, setHasTeamSalaryModule] = useState(false);
  const [hasTeamCompensationModule, setHasTeamCompensationModule] =
    useState(false);

  const toggleCollapsedLayout = (event) => {
    setCollapsedLayoutOpen(!collapsedLayoutOpen);
    event.preventDefault();
  };
  useEffect(() => {
    const managerAccess = checkPermission({
      permId: FUNCTIONALITY_PERMISSION_CONSTANTS.MANAGER_ACCESS.id,
      permissions: user.permissions,
      view: view,
      modules: modules
    });
    const myProfileModule = checkPermission({
      permId: FUNCTIONALITY_PERMISSION_CONSTANTS.MY_PROFILE_MODULE.id,
      permissions: user.permissions,
      view: view,
      modules: modules
    });
    const myLeavesModule = checkPermission({
      permId: FUNCTIONALITY_PERMISSION_CONSTANTS.MY_LEAVES_MODULE.id,
      permissions: user.permissions,
      view: view,
      modules: modules
    });
    const teamLeavesModule = checkPermission({
      permId: FUNCTIONALITY_PERMISSION_CONSTANTS.TEAM_LEAVES_MODULE.id,
      permissions: user.permissions,
      view: view,
      modules: modules
    });
    const teamSalaryModule = checkPermission({
      permId: FUNCTIONALITY_PERMISSION_CONSTANTS.TEAM_SALARY_MODULE.id,
      permissions: user.permissions,
      view: view,
      modules: modules
    });
    const teamCompensanModule = checkPermission({
      permId: FUNCTIONALITY_PERMISSION_CONSTANTS.TEAM_COMPENSATION_MODULE.id,
      permissions: user.permissions,
      view: view,
      modules: modules
    });
    const myCompensationModule = checkPermission({
      permId: FUNCTIONALITY_PERMISSION_CONSTANTS.MY_COMPENSATION_MODULE.id,
      permissions: user.permissions,
      view: view,
      modules: modules
    });
    setManagerAccess(managerAccess);
    setMyLeavesModule(myLeavesModule);
    setMyProfileModule(myProfileModule);
    setTeamLeavesModule(teamLeavesModule);
    setMyCompensationModule(myCompensationModule);
    setHasTeamSalaryModule(teamSalaryModule);
    setHasTeamCompensationModule(teamCompensanModule);
  }, []);
  return (
    <>
      <ul>
        {hasMyProfileModule && (
          <li>
            <NavLink
              // activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to="/myProfile">
              <span className="sidebar-icon">
                <BallotTwoToneIcon />
              </span>
              Me
            </NavLink>
          </li>
        )}
        {hasMyLeavesModule && (
          <li>
            <NavLink
              // activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to="/myLeaves">
              <span className="sidebar-icon">
                <BallotTwoToneIcon />
              </span>
              My Leaves
            </NavLink>
          </li>
        )}
        {hasMyCompensationModule && (
          <li>
            <NavLink
              // activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to="/myCompensation">
              <span className="sidebar-icon">
                <BallotTwoToneIcon />
              </span>
              My Compensation
            </NavLink>
          </li>
        )}
        {hasManagerAccess && (
          <li style={{ cursor: 'pointer' }}>
            <a
              to="#/"
              onClick={toggleCollapsedLayout}
              className={clsx({ active: collapsedLayoutOpen })}>
              <span className="sidebar-icon">
                <BallotTwoToneIcon />
              </span>
              <span className="sidebar-item-label">My Team</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon />
              </span>
            </a>
            <Collapse in={collapsedLayoutOpen}>
              <ul>
                <li>
                  <NavLink
                    // activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/my-team">
                    My Team
                  </NavLink>
                </li>
                {hasTeamLeavesModule && (
                  <li>
                    <NavLink
                      // activeClassName="active"
                      onClick={toggleSidebarMobile}
                      className="nav-link-simple"
                      to="/pendingLeaveApprovals">
                      Team Leaves
                    </NavLink>
                  </li>
                )}
                {hasTeamSalaryModule && (
                  <li>
                    <NavLink
                      // activeClassName="active"
                      onClick={toggleSidebarMobile}
                      className="nav-link-simple"
                      to="/pendingLoanApprovals">
                      Team Salary
                    </NavLink>
                  </li>
                )}
                {hasTeamCompensationModule && (
                  <li>
                    <NavLink
                      // activeClassName="active"
                      onClick={toggleSidebarMobile}
                      className="nav-link-simple"
                      to="/teamCompensation">
                      Team Compensation
                    </NavLink>
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
      </ul>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.Auth.user,
  view: state.Auth.view,
  modules: state.Auth.modules
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
