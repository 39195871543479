import React from 'react';
import { Button, Dialog } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

const ConfirmationAlert = ({
  showModal,
  message,
  saveData,
  handleCloseConfirmModal
}) => {
  return (
    <>
      <Dialog
        open={showModal}
        onClose={handleCloseConfirmModal}
        classes={{ paper: 'shadow-sm-dark rounded-sm' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
              <FontAwesomeIcon
                icon={['far', 'keyboard']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Are you sure?</h4>
          <p className="mb-0 text-black-50">{message}</p>
          <div className="pt-4">
            <Button
              onClick={handleCloseConfirmModal}
              className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label">No</span>
            </Button>
            <Button onClick={saveData} className="btn-first btn-pill mx-1">
              <span className="btn-wrapper--label">Yes</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user
});

const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationAlert);
